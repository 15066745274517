//// this is the front page
// export default Navbar;
import "./index.css";
import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
//import { analytics } from "../../config/firebase.js";
//import pluto from '..//../pluto3.png';
//import { useCart } from "..//../pages/cartContext.js";
import { useAuth } from "../auth-email.js";
import logoClear from "..//../logoClear.png";
import { FaUserCircle } from "react-icons/fa";
import { auth } from "..//../config/firebase.js";
import { signOut } from "firebase/auth";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "react-bootstrap";
import { ButtonGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

const Nav = styled.nav`
  background-color: ${(props) => (props.$isMobile ? "white" : "white")};
  color: ${(props) => (props.$isMobile ? "#092f7a" : "#092f7a")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  z-index: 100;
  top: 0;
`;

const NavMenu = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  margin-left: 10px;
  display: none; /* Initially hidden */
  margin-bottom: 30px;

  &.menu-open {
    display: block; /* Show menu */
  }
`;

const NavItem = styled.li`
  margin-bottom: 3px;
`;

const MenuToggle = styled.div`
  display: flex; /* Ensure it's displayed as a flex container */
  flex-direction: column; /* Stack bars vertically */
  cursor: pointer; /* Change cursor to pointer */
`;

const Bar = styled.div`
  width: 23px;
  height: 3px;
  background-color: #092f7a;
  margin: 2px 0;
  justify-content: left;
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();
  //const { cartCount } = useCart();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  //const handleNavigateToCart = () => navigate("/cart");
  const { currentUser } = useAuth();

  const [showPopup, setShowPopup] = useState(false);

  //const togglePopup = () => setShowPopup(!showPopup);

  useEffect(() => {
    //console.log('showPopup:', showPopup); // Debugging state changes
  }, [showPopup]);

  useEffect(() => {
    //console.log('currentUser:', currentUser); // Debugging currentUser changes
  }, [currentUser]);

  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };

  //** DROPDOWNS */

  // Handle navigation and close dropdowns
  //** DROPDOWNS */
  const dropdownRef = useRef(null);
  const dropdown2Ref = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdown2Open, setDropdown2Open] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen((prev) => {
      const newState = !prev;
      console.log("Dropdown state:", newState);
      return newState;
    });
  };

  const toggleDropdown2 = () => {
    console.log("Dropdown2 toggled");
    setDropdown2Open((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
    if (dropdown2Ref.current && !dropdown2Ref.current.contains(event.target)) {
      setDropdown2Open(false);
    }
  };

  const handleNavClick = (event, dropdown) => {
    event.stopPropagation(); // Prevent the click from bubbling up to the document
    // Get the navigation path from the clicked element
    const navigateTo = event.currentTarget.getAttribute("data-navigate");
    if (navigateTo) {
      // Close the appropriate dropdown before navigation
      if (dropdown === 1) {
        setDropdownOpen(false);
      } else if (dropdown === 2) {
        setDropdown2Open(false);
      }
      navigate(navigateTo); // Navigate to the desired page
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the dropdowns
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownCategories = [
    {
      title: "Free",
      variant: "primary",
      items: [
        { label: "Trova Bandi", to: "/freeSearch" },
        { label: "Analisi Storici", to: "/incentivoAnalysis" },
        {
          label: "Agente FNC",
          to: "webBot/asst_kiyV1MFD4JJGNzGqO4V5KQBy/",
        },
      ],
    },
    {
      title: "Aziende",
      variant: "primary",
      items: [
        { label: "Trova Bandi per Me", to: "/search" },
        {
          label: "Pianificatore Obiettivi",
          to: "/goals/asst_z07YzNlfWW5LFE2not8HmSZ2/",
        },
        { label: "Come Funziona", to: "/aboutUser" },
        { label: "Prezzi", to: "/service-offers" },
      ],
    },
    {
      title: "Professionisti",
      variant: "success",
      items: [
        { label: "Trova Bandi per Clienti", to: "/searchpros" },
        { label: "Come Funziona", to: "/aboutUserPro" },
        { label: "Prezzi", to: "/service-offers-pros" },
        { label: "Diventa Partner", to: "/diventapartner" },
      ],
    },
   /*  {
      title: "Products",
      variant: "info",
      items: [
        { label: "Search & News", to: "/product1" },
        { label: "Fast Forms", to: "/product2" },
        { label: "Addons", to: "/product3" },
      ],
    }, */
    {
      title: "News",
      variant: "warning",
      items: [
        { label: "Latest News", to: "/news" },
        { label: "Press Releases", to: "/news/press-releases" },
        { divider: true },
        { label: "Archive", to: "/news/archive" },
      ],
    },
  ];

  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const [openCategories, setOpenCategories] = useState([]);
  const toggleCategory = (title) => {
    if (openCategories.includes(title)) {
      setOpenCategories(openCategories.filter((t) => t !== title));
    } else {
      setOpenCategories([...openCategories, title]);
    }
  };

  const isMobile = windowWidth < 768; 

  return (
    <Nav
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px",
        paddingTop: "20px",
      }}
      $isMobile={isMobile} 
    >
      <div className="navContainer">
      <header className="logo-container">
        <button onClick={() => navigate("/")} className="headerStylesw">
          {windowWidth < 768 ? (
            <>
              <img
                src={logoClear}
                alt="Logo"
                className="brand-logo-fp"
              />
              {/* <img
                src={namebrand}
                alt="Brand Name"
                className="brand-name-fp"
              /> */}
              <div className="brand-name-fp">
                <span className="blue-letter">INCENT</span>
                <span className="red-letter">I</span>
                <span className="blue-letter">V</span>
                <span className="red-letter">A</span>
                <span className="blue-letter">TO</span>
              </div>
            </>
          ) : (
            <>
              <img
                src={logoClear}
                alt="Logo"
                className="brand-logo-fp"
              />
              <div className="brand-name-fp">
                <span className="blue-letter">INCENT</span>
                <span className="red-letter">I</span>
                <span className="blue-letter">V</span>
                <span className="red-letter">A</span>
                <span className="blue-letter">TO</span>
              </div>
            </>
            
          )}
        </button>
      </header>
        {/* Only show burger menu on mobile */}
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            flexGrow: 1,
            paddingRight: "30px",
          }}
        >
          {isMobile ? (
            <div className="menuToggleContainerw" onClick={toggleMenu}>
              <MenuToggle>
                <Bar />
                <Bar />
                <Bar />
              </MenuToggle>
            </div>
          ) : (
            // Only show desktop nav items if view is greater than 768
            <div
              style={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                marginLeft: "30px",
              }}
            >
              <div className="dropdown-buttons">
                {dropdownCategories.map((category) => (
                  <DropdownButton
                    as={ButtonGroup}
                    key={category.title}
                    id={`dropdown-variants-${category.title}`}
                    variant="secondary"
                    title={category.title}
                    className="custom-dropdown-button" 
                  >
                    {category.items.map((item, index) =>
                      item.divider ? (
                        <Dropdown.Divider
                          key={`divider-${category.title}-${index}`}
                        />
                      ) : (
                        <Dropdown.Item
                          as={Link}
                          to={item.to}
                          key={`${category.title}-item-${index}`}
                        >
                          {item.label}
                        </Dropdown.Item>
                      )
                    )}
                  </DropdownButton>
                ))}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
              <div>
      {currentUser ? (
        <>
          <FaUserCircle
            className="user-icon"
            style={{ fontSize: "24px", cursor: "pointer" }}
            title={currentUser.email} // Tooltip with email
            onClick={() => navigate("/userprofile")}
          />
          {/* Optionally, you can include the logout button here */}
          {/* <button onClick={logout} style={{ marginLeft: "10px" }}>
            Logout
          </button> */}
        </>
      ) : (
        <Link to="/login" className="login-promptw" style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
        Log in
      </Link>
              )}
            </div>
              </div>
            </div>
          )}
        </div>
      </div>
 
      {/* Mobile Dropdown Menu */}
      {isMobile && isMenuOpen && (
        <NavMenu className="menu-font-size menu-open">
          {dropdownCategories.map((category) => (
            <div key={category.title} className="mobile-category">
              {/* Category Title */}
              <div
                onClick={() => toggleCategory(category.title)}
                className="mobile-category-title"
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  color: "#092f7a",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px 0",
                  borderBottom: "1px solid #eaeaea",
                }}
              >
                <span>{category.title}</span>
                {/* Optional: Add an arrow icon to indicate expandable */}
                <span style={{ transform: openCategories.includes(category.title) ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}>
                  ▶
                </span>
              </div>
              {/* Category Items */}
              {openCategories.includes(category.title) && (
                <div className="mobile-category-items" style={{ paddingLeft: "15px", paddingTop: "5px" }}>
                  {category.items.map((item, idx) =>
                    item.divider ? (
                      <hr
                        key={`divider-${category.title}-${idx}`}
                        style={{ borderColor: "#092f7a" }}
                      />
                    ) : (
                      <NavItem
                        key={`${category.title}-item-${idx}`}
                        onClick={closeMenu}
                      >
                        <NavLink
                          to={item.to}
                          style={{
                            color: "#092f7a",
                            textDecoration: "none",
                            padding: "8px 0",
                            display: "block",
                          }}
                        >
                          {item.label}
                        </NavLink>
                      </NavItem>
                    )
                  )}
                </div>
              )}
            </div>
          ))}

          {/* User Section in Mobile Menu */}
          <div
            className="mobile-user-section"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
              flexDirection: "column",
            }}
          >
            {currentUser ? (
              <>
                <FaUserCircle
                  className="user-icon"
                  style={{ fontSize: "24px", marginBottom: "10px" }}
                  title={currentUser.email}
                  onClick={() => {
                    navigate('/userprofile');
                    closeMenu();
                  }}
                />
                <span
                  className="user-email"
                  style={{
                    padding: "3px",
                    fontSize: "14px",
                    color: "#092f7a",
                    marginBottom: "10px",
                  }}
                >
                  {currentUser.email}
                </span>
                <button
                  onClick={logout}
                  className="logout-button"
                  style={{
                    marginLeft: "0",
                    padding: "5px 10px",
                    backgroundColor: "#f8f9fa",
                    border: "1px solid #dc3545",
                    color: "#dc3545",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Logout
                </button>
              </>
            ) : (
              <Link
                to="/login"
                className="login-promptw"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: '#092f7a',
                  padding: "8px 0",
                  display: "block",
                }}
                onClick={closeMenu}
              >
                Log in
              </Link>
            )}
          </div>
        </NavMenu>
      )}
    </Nav>
  );
};

export default Navbar;

{
  /* <div 
          style={{ top: "15px", left: "30px" }} 
          className="cart-icon-container"
          onClick={handleNavigateToCart}
        >
          <i className="fa fa-shopping-cart"></i>
          {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
        </div> */
}
