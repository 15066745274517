import { auth, googleProvider } from "../config/firebase.js";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ".//login.css";

function LogIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  // Use `useEffect` to parse URL parameters on component mount
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const assistantFromUrl = urlParams.get("assistant");
    const incentiveIdFromUrl = urlParams.get("incentiveId");

    //console.log("Retrieved from URL parameters:", { assistantFromUrl, incentiveIdFromUrl });

    if (assistantFromUrl && incentiveIdFromUrl) {
        // Optionally store in localStorage for later use, if needed
        localStorage.setItem("assistant", assistantFromUrl);
        localStorage.setItem("incentiveId", incentiveIdFromUrl);
    }
  }, []); // Runs once on mount

  const signIn = async (event) => {
    event.preventDefault();
    try {
        //console.log("Attempting sign in with email:", email);
        
        await signInWithEmailAndPassword(auth, email, password);
        
        // Retrieve `assistant` and `incentiveId` directly from URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const assistant = urlParams.get("assistant");
        const incentiveId = urlParams.get("incentiveId");

        //console.log("Retrieved from URL parameters in signIn:", { assistant, incentiveId });

        // Ensure `assistant` and `incentiveId` are available before proceeding
        if (!assistant || !incentiveId) {
            setErrorMessage("Please select an incentive before logging in.");
            console.warn("assistant or incentiveId missing; redirecting to home.");
            navigate("/"); // Redirect to homepage or another appropriate page
            return;
        }
        
        // Push the sign-in event to GTM's dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "userSignIn", // Custom event name
          email: email, // Email (ensure no sensitive data is exposed)
          assistant: assistant,
          incentiveId: incentiveId,
          timestamp: new Date().toISOString(), // Add timestamp for tracking
        });

        startNewConversation(
            "atecoL3_value", "regione_value", assistant, incentiveId,
            "firstName_value", "companyName_value", "companySize_value",
            "companyEmployees_value", "companyTurnover_value"
        );

        // Clear localStorage if you don’t need to persist it further
        localStorage.removeItem("assistant");
        localStorage.removeItem("incentiveId");

        //console.log("Cleared assistant and incentiveId from localStorage.");

    } catch (err) {
        console.error("Login failed:", err);
        setErrorMessage("Login failed. Please try again.");
    }
  };
  

  const startNewConversation = async (
    atecoL3, regione, assistant, incentiveId,
    firstName, companyName, companySize,
    companyEmployees, companyTurnover
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = `Ciao, e questo incentivo buona per nostra azienda? Io sono ${firstName} e l'azienda si chiama ${companyName}. Siamo in ${regione} con ateco: ${atecoL3}. Siamo un ${companySize} con ${companyEmployees} dipendenti e turnover annuale di €${companyTurnover}.`;
      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message: userMessage }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      localStorage.setItem("currentThreadId", data.thread_id);

      if (data.thread_id && assistant) {
        navigate(`/threadUnic/${assistant}/${data.thread_id}/${incentiveId}`, {
          state: { title: `Discussion on ATECO ${atecoL3} in ${regione}` },
        });
      } else {
        console.error("Thread ID or Assistant ID was not returned from the server.");
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  }; 


  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate(from);
    } catch (err) {
      console.error(err);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };

  const resetPassword = async () => {
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset email sent!");
      } catch (err) {
        console.error(err);
        alert("Failed to send password reset email.");
      }
    } else {
      alert("Please enter your email address.");
    }
  };



  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', paddingTop: "20px" }}>
    <div className="login-container">
      <div className="section">
        <h2>Create Account</h2>
        <button type="button" className="button" onClick={() => navigate("/signup")}>Sign Up - New User</button>
      </div>
      <div className="section">
        <h2>Login</h2>
        <form className="login-form" onSubmit={signIn}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              placeholder="Email..."
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              placeholder="Password..."
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="button-group">
            <button type="submit">Sign In</button>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </div>
        </form>
        <div className="button-group">
          <button
            type="button"
            onClick={signInWithGoogle}
            className="button-style"
          >
            Sign In With Google
          </button>
        </div>
      </div>
      <div className="horizontal-group">
        <button type="button" className="button-group" onClick={resetPassword}>Reset Password</button>
        <button type="button" className="button-group" onClick={logout}>Logout</button>
      </div>
      <footer>
        {/* <p>Email: info@capital-ai.eu</p> */}

      </footer>
    </div>
    </div>
  );
}

export default LogIn;

