import React from 'react';

const Contact = () => {
	return (
		<div>
			<h1 style={{fontSize: '14px', padding: '20px'}}>

			Effetivo: Gennaio 22, 2025

<br /> <br /> Noi di CapitalAI srl rispettiamo la tua privacy e ci impegniamo a proteggere i dati personali che raccogliamo da te o su di te. Questa Informativa sulla Privacy descrive come trattiamo i dati personali raccolti quando utilizzi il nostro sito web, le applicazioni e i servizi.

<br /> <br />Conformità al GDPR e alla normativa italiana
Il trattamento dei tuoi dati personali avviene in conformità con il Regolamento Generale sulla Protezione dei Dati (GDPR), il Codice Privacy italiano (D.Lgs. 196/2003) e successivi aggiornamenti, inclusi i requisiti per l'uso di strumenti di analisi come Google Analytics.
<br /> <br />
1. Informazioni personali che raccogliamo
Raccogliamo i seguenti tipi di informazioni personali:

Informazioni personali fornite dall'utente
Informazioni sull'account: nome, dettagli di contatto, credenziali dell'account, informazioni di pagamento e cronologia delle transazioni.
Informazioni aziendali: raccogliamo la tua Partita IVA per ottenere dati pubblici relativi alla tua attività (circa 400 punti dati) per fornirti servizi personalizzati di matching di incentivi e agevolazioni.
Contenuti utente: dati inclusi negli input, nei file caricati o nel feedback fornito.
Informazioni di comunicazione: nome, dettagli di contatto e contenuto dei messaggi inviati.
Social Media: informazioni fornite interagendo con le nostre pagine sui social media (Instagram, Facebook, ecc.).
Informazioni raccolte automaticamente
Dati tecnici: indirizzo IP, tipo di browser, sistema operativo, data/ora della visita e interazioni con il nostro sito.
Cookies e strumenti di analisi: utilizziamo cookie per operare e migliorare i nostri servizi. Google Analytics raccoglie dati aggregati sull'uso del sito, come pagine visitate, durata della visita e tipo di dispositivo.
Nota importante sull'uso di Google Analytics
Google Analytics è configurato in modalità Consent Mode, che garantisce il rispetto delle scelte di consenso degli utenti per i cookie di analisi, come richiesto dal GDPR. I dati raccolti sono pseudonimizzati e utilizzati solo per migliorare i nostri servizi.

<br /> <br />2. Come utilizziamo le informazioni personali
Utilizziamo i tuoi dati personali per:

Fornire e migliorare i nostri servizi.
Personalizzare la tua esperienza sul nostro sito.
Adempiere a obblighi legali, inclusa la protezione dei tuoi diritti.
<br /> <br />3. Condivisione delle informazioni personali
Condividiamo le informazioni personali solo nei seguenti casi:

Fornitori di servizi: per l'hosting, i servizi di analisi (es. Google Analytics) e altri servizi necessari.
Autorità legali: in caso di obblighi legali.
<br /> <br />4. I tuoi diritti
Secondo il GDPR, hai il diritto di:

Accedere, rettificare o cancellare i tuoi dati personali.
Limitare o opporsi al trattamento dei tuoi dati.
Ritirare il consenso in qualsiasi momento.
Presentare un reclamo all'Autorità Garante per la Protezione dei Dati Personali in Italia.
Puoi esercitare i tuoi diritti contattandoci a info@incentivato.it.

<br /> <br />5. Sicurezza e conservazione dei dati
Adottiamo misure tecniche e organizzative per proteggere i tuoi dati personali. I dati saranno conservati solo per il tempo necessario agli scopi indicati o come richiesto dalla legge.

<br /> <br />6. Bambini
I nostri servizi non sono destinati a minori di 13 anni. Se sospetti che un minore abbia fornito informazioni personali, contattaci a info@incentivato.it.

<br /> <br />7. Modifiche all'informativa
Questa informativa può essere aggiornata periodicamente. Eventuali modifiche saranno pubblicate su questa pagina.

<br /> <br />8. Contatti
Per domande sulla nostra Informativa sulla Privacy, contattaci a:
<br /> <br />CapitalAI srl
<br /> <br />Email: info@incentivato.it			
			</h1>
		</div>
	);
};

export default Contact;
