///imports
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { db, auth } from "..//..//src/config/firebase.js";
import {
  getDocs,
  getDoc,
  collection,
  addDoc,
  serverTimestamp,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
  startAfter,
  onSnapshot,
} from "firebase/firestore";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//..//src/components/userPrompt.js";
import App from "..//App.js";
import { useParams } from "react-router-dom";
import IncentiveSearch from "./agent2.js";
import Typewriter from "..//components/Typewriter.js";
import SSEDisplay from "..//components/SSEDisplay.js";
import renderCriteria from "..//components/criteria.js";
import RatingComponent from "..//components/RatingComponent.js";
//import LongPollDisplay from '..//components/LongPolling.js';
import DOMPurify from "dompurify";
import italyMap from ".//../it.svg";
import regionPaths from ".//../pages/regionPaths.js";

function RNA() {
  const [regionData, setRegionData] = useState([]);
  const [colorScale, setColorScale] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedAteco, setSelectedAteco] = useState("All");
  const [selectedMetric, setSelectedMetric] = useState("grants_count");

  const regionIdMap = {
    Abruzzo: "IT65",
    Basilicata: "IT77",
    Calabria: "IT78",
    Campania: "IT72",
    "Emilia-Romagna": "IT45",
    "Friuli-Venezia Giulia": "IT36",
    Lazio: "IT62",
    Liguria: "IT42",
    Lombardia: "IT25",
    Marche: "IT57",
    Molise: "IT67",
    Piemonte: "IT21",
    Puglia: "IT75",
    Sardegna: "IT88",
    Sicilia: "IT82",
    Toscana: "IT52",
    Umbria: "IT55",
    "Valle d'Aosta": "IT23",
    Veneto: "IT34",
    "Trentino-Alto Adige": "IT32",
  };

  const atecoDescriptions = {
    A: "Agricoltura",
    B: "Mining",
    C: "Manufacturing",
    D: "Energia",
    E: "Water & Waste",
    F: "Construction",
    G: "Wholesale, Retail & Mechanics",
    H: "Transportation & Storage",
    I: "Accomodation & Food",
    J: "Information & Communication",
    K: "Finance & Insurance",
    L: "Real Estate",
    M: "Professional & Scientific",
    N: "Admin & Support",
    O: "Public Admin & Defense",
    P: "Education",
    Q: "Health & Social Work",
    R: "Arts, Entertainment & Sport",
    S: "Other Services",
    T: "Domestic Personnel",
    U: "Extraterritorial",
    Z: "Other",
  };

  const metricLabels = {
    grants_count: "Quantita (000s)",
    total_funds_millions: "Spesi (€bn)",
    avg_funding: "Medi (€k)",
    max_funding: "Max (€k)",
    median_funding: "Mediani (€k)",
    min_funding: "Min (€k)",
    std_dev_funding: "Deviazione (€k)",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "rnaCombo"));
        const data = querySnapshot.docs.map((doc) => {
          const regionData = doc.data();
          const { ATECO } = regionData;

          // Initialize metrics
          let totalGrantsCount = 0;
          let totalFundsMillions = 0;
          let avgFunding = 0;
          let maxFunding = 0;
          let medianFunding = 0;
          let minFunding = 0;
          let stdDevFunding = 0;
          let atecoCount = 0;

          // Iterate over ATECO codes and accumulate metrics for the selected ATECO
          Object.entries(ATECO || {}).forEach(([atecoCode, metrics]) => {
            if (selectedAteco === "All" || atecoCode === selectedAteco) {
              totalGrantsCount += metrics.grants_count || 0;
              totalFundsMillions += metrics.total_funds_millions || 0;
              avgFunding += metrics.avg_funding || 0;
              maxFunding += metrics.max_funding || 0;
              medianFunding += metrics.median_funding || 0;
              minFunding += metrics.min_funding || 0;
              stdDevFunding += metrics.std_dev_funding || 0;
              atecoCount++;
            }
          });

          // Calculate averages
          avgFunding = atecoCount > 0 ? avgFunding / atecoCount : 0;
          maxFunding = atecoCount > 0 ? maxFunding / atecoCount : 0;
          medianFunding = atecoCount > 0 ? medianFunding / atecoCount : 0;
          minFunding = atecoCount > 0 ? minFunding / atecoCount : 0;
          stdDevFunding = atecoCount > 0 ? stdDevFunding / atecoCount : 0;

          // Return the aggregated metrics
          return {
            id: doc.id,
            ...regionData,
            grants_count: totalGrantsCount,
            total_funds_millions: totalFundsMillions,
            avg_funding: avgFunding,
            max_funding: maxFunding,
            median_funding: medianFunding,
            min_funding: minFunding,
            std_dev_funding: stdDevFunding,
          };
        });

        //console.log("Processed Region Data:", data); // Debugging
        setRegionData(data);
      } catch (error) {
        console.error("Error fetching region data:", error);
      }
    };

    fetchData();
  }, [selectedAteco]); // Re-run when selectedAteco changes

  useEffect(() => {
    if (regionData.length > 0) {
      // Get the maximum value of the selected metric
      const maxMetricValue = Math.max(
        ...regionData.map((region) => region[selectedMetric] || 0)
      );

      const scale = regionData.reduce((acc, region) => {
        const svgId = regionIdMap[region.Region];
        if (svgId) {
          const metricValue = region[selectedMetric] || 0;
          const intensity = maxMetricValue
            ? Math.floor((metricValue / maxMetricValue) * 255)
            : 0;

          // Use the blue gradient based on intensity
          const red = Math.floor(
            0x00 + ((255 - intensity) / 255) * (255 - 0x00)
          );
          const green = Math.floor(
            0x7b + ((255 - intensity) / 255) * (255 - 0x7b)
          );
          const blue = Math.floor(
            0xff + ((255 - intensity) / 255) * (255 - 0xff)
          );

          acc[svgId] = `rgb(${red}, ${green}, ${blue})`;
        }
        return acc;
      }, {});
      setColorScale(scale);
    }
  }, [regionData, selectedMetric]);

  useEffect(() => {
    //console.log("Region Paths:", regionPaths);
    //console.log("Color Scale:", colorScale);
  }, [colorScale]);

  const selectedRegionMetrics =
    regionData.find((region) => regionIdMap[region.Region] === selectedRegion)
      ?.ATECO || {};

  useEffect(() => {
    //console.log("Selected Region Metrics:", selectedRegionMetrics);
  }, [selectedRegionMetrics]);

  const selectedRegionData =
    regionData.find(
      (region) => regionIdMap[region.Region] === selectedRegion
    ) || {};

  useEffect(() => {
    //console.log("Selected Region:", selectedRegion);
  }, [selectedRegion]);

  useEffect(() => {
    //console.log("Filtered Region Data:", regionData);
  }, [regionData]);

  const formatThousands = (value) => {
    if (value == null) return "0"; // Handle null or undefined values
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 1, // Set minimum to 1 decimal place
      maximumFractionDigits: 1, // Limit to 1 decimal place
    }).format(value / 1000); // Divide by 1,000 for thousands
  };

  const format1 = (value) => {
    if (value == null) return "0"; // Handle null or undefined values
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0, // Set minimum to 1 decimal place
      maximumFractionDigits: 0, // Limit to 1 decimal place
    }).format(value / 1); // Divide by 1,000 for thousands
  };

  const getRegionName = (regionCode) => {
    const regionEntry = Object.entries(regionIdMap).find(
      ([, code]) => code === regionCode
    );
    return regionEntry ? regionEntry[0] : "Scegli un regione";
  };

  const navigate = useNavigate();

  return (
    <div style={{ marginLeft: "10px" }}>
        <button
      onClick={() => navigate("/incentivoAnalysisMain")}
      style={{
        padding: "10px 20px",
        backgroundColor: "#4CAF50", // Green background
        color: "white", // White text
        border: "none", // No border
        borderRadius: "5px", // Rounded corners
        cursor: "pointer", // Pointer on hover
        fontSize: "16px",
      }}
    >
      Vai all'analisi degli incentivi
    </button>
      <h2 style={{ padding: "20px", color: "#FF5758" }}>
        Analisi degli incentivi storici 2022-2024
      </h2>
      <div className="analisi-filters">
        <div style={{ marginBottom: "20px" }}>
          <label style={{ marginRight: "10px" }} htmlFor="ateco-select">
            ATECO:{" "}
          </label>
          <select
            id="ateco-select"
            value={selectedAteco}
            onChange={(e) => setSelectedAteco(e.target.value)}
          >
            <option value="All">Tutti</option>
            {Object.keys(regionData[0]?.ATECO || {})
              .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
              .map((atecoCode) => (
                <option key={atecoCode} value={atecoCode}>
                  {`${atecoCode} - ${
                    atecoDescriptions[atecoCode] || "Unknown"
                  }`}
                </option>
              ))}
          </select>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label style={{ marginRight: "10px" }} htmlFor="metric-select">
            Metrica:{" "}
          </label>
          <select
            id="metric-select"
            value={selectedMetric}
            onChange={(e) => setSelectedMetric(e.target.value)}
          >
            <option value="grants_count">Quantita (000s)</option>
            <option value="total_funds_millions">Spesi (€bn)</option>
            <option value="avg_funding">Medi (€k)</option>
            <option value="max_funding">Max (€k)</option>
            <option value="median_funding">Mediani (€k)</option>
            <option value="min_funding">Min (€k)</option>
            <option value="std_dev_funding">Deviazione (€k)</option>
          </select>
        </div>
      </div>
      <div className="mapflex">
        <svg viewBox="0 0 1000 1000" style={{ width: "60%", height: "60%" }}>
          {Object.keys(regionPaths).map((regionId) => {
            // Find the corresponding region data
            const region = regionData.find(
              (region) => regionIdMap[region.Region] === regionId
            );
            const metricValue = region
              ? region[selectedMetric]?.toFixed(2) || 0
              : "N/A";

            return (
              <path
                key={regionId}
                id={regionId}
                d={regionPaths[regionId]}
                fill={colorScale[regionId] || "#ccc"}
                stroke="lightgrey"
                onClick={() => setSelectedRegion(regionId)}
                style={{ cursor: "pointer" }}
              >
                {/* Hover tooltip */}
                <title>{`${
                  region?.Region || "Unknown"
                }: ${metricValue} ${selectedMetric.replace(/_/g, " ")}`}</title>
              </path>
            );
          })}
        </svg>

        <div style={{ marginTop: "20px", paddingLeft: "20px" }}>
          {/* {regionData.length > 0 ? (
          <ul>
            {regionData.map((region) => (
              <li key={region.id}>
                {region.Region}: {region.grants_count || 0} grants
              </li>
            ))}
          </ul>
        ) : (
          <p>No region data available.</p>
        )} */}
        </div>

        <div
  style={{
    marginTop: "20px",
    width: "90%",
    paddingLeft: "20px",
    color: "grey",
  }}
>
  <h4>
    {getRegionName(selectedRegion)} {metricLabels[selectedMetric] || selectedMetric}
  </h4>
  {selectedRegionMetrics && Object.keys(selectedRegionMetrics).length > 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: window.innerWidth <= 768 ? "row" : "column",
      }}
    >
      {window.innerWidth > 768 ? (
        <svg width="100%" height="400">
          {/* Horizontal Bar Chart */}
          <line
            x1="50"
            y1="350"
            x2="100%"
            y2="350"
            stroke="white"
            strokeWidth="2"
          />
          <line
            x1="50"
            y1="350"
            x2="50"
            y2="20"
            stroke="white"
            strokeWidth="2"
          />
          <text
            x="50%"
            y="400"
            textAnchor="middle"
            fontSize="20"
            fill="grey"
          >
            ATECO
          </text>
          {(() => {
            const dataEntries = Object.entries(selectedRegionMetrics).sort(
              ([a], [b]) => a.localeCompare(b)
            );
            const maxMetric = Math.max(
              ...dataEntries.map(([_, metrics]) => metrics[selectedMetric] || 0)
            );
            const barWidth = 30;
            const barSpacing = 10;

            return dataEntries.map(([atecoCode, metrics], index) => {
              const metricValue = metrics[selectedMetric] || 0;
              const barHeight = (metricValue / maxMetric) * 300;
              const xPosition = 50 + index * (barWidth + barSpacing);

              return (
                <g key={atecoCode}>
                  <rect
                    x={xPosition}
                    y={350 - barHeight}
                    width={barWidth}
                    height={barHeight}
                    fill="#FF5758"
                    stroke="white"
                    rx="1%"
                    ry="1%"
                  />
                  <text
                    x={xPosition + barWidth / 2}
                    y={350 - barHeight - 5}
                    textAnchor="middle"
                    fontSize="14"
                    fill="grey"
                  >
                    {formatThousands(metricValue)}
                  </text>
                  <text
                    x={xPosition + barWidth / 2}
                    y={370}
                    textAnchor="middle"
                    fontSize="14"
                    fill="grey"
                  >
                    {atecoCode}
                  </text>
                </g>
              );
            });
          })()}
        </svg>
      ) : (
        <svg
          width="400"
          height={Object.keys(selectedRegionMetrics).length * 40}
        >
          {/* Vertical Bar Chart */}
          <line
            x1="50"
            y1="20"
            x2="50"
            y2="100%"
            stroke="white"
            strokeWidth="2"
          />
          <line
            x1="50"
            y1="100%"
            x2="400"
            y2="100%"
            stroke="white"
            strokeWidth="2"
          />
          <text
            x="20"
            y="50%"
            textAnchor="middle"
            fontSize="20"
            fill="grey"
            transform="rotate(-90, 20, 200)"
          >
            ATECO
          </text>
          {(() => {
            const dataEntries = Object.entries(selectedRegionMetrics).sort(
              ([a], [b]) => a.localeCompare(b)
            );
            const maxMetric = Math.max(
              ...dataEntries.map(([_, metrics]) => metrics[selectedMetric] || 0)
            );
            const barHeight = 30;
            const barSpacing = 10;

            return dataEntries.map(([atecoCode, metrics], index) => {
              const metricValue = metrics[selectedMetric] || 0;
              const barWidth = (metricValue / maxMetric) * 300;
              const yPosition = 20 + index * (barHeight + barSpacing);

              return (
                <g key={atecoCode}>
                  <rect
                    x={50}
                    y={yPosition}
                    width={barWidth}
                    height={barHeight}
                    fill="#FF5758"
                    stroke="white"
                    rx="1%"
                    ry="1%"
                  />
                  <text
                    x={50 + barWidth + 5}
                    y={yPosition + barHeight / 2}
                    textAnchor="start"
                    fontSize="14"
                    fill="grey"
                  >
                    {formatThousands(metricValue)}
                  </text>
                  <text
                    x={30}
                    y={yPosition + barHeight / 2}
                    textAnchor="end"
                    fontSize="14"
                    fill="grey"
                  >
                    {atecoCode}
                  </text>
                </g>
              );
            });
          })()}
        </svg>
      )}
    </div>
  ) : (
    <p>Select a region</p>
  )}
</div>

      </div>
      <div
        style={{
          marginTop: "20px",
          width: "90%",
          paddingLeft: "20px",
          fontSize: "14px",
          border: "1px solid white",
        }}
      >
        <h3 style={{ color: "grey" }}> {getRegionName(selectedRegion)}</h3>
        {selectedRegion ? (
          <>
            <table
              style={{
                color: "grey",
                borderCollapse: "collapse",
                width: "20%",
                marginBottom: "20px",
                border: "1px solid white",
              }}
            >
              <thead>
                <tr>
                  <th style={{ padding: "6px" }}>Quantita (000s)</th>
                  <th style={{ padding: "6px" }}>Spesi (€m)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: "3px", border: "1px solid white" }}>
                    <div className="green-cell">
                      {format1(selectedRegionData.grants_count)}
                    </div>
                  </td>
                  <td style={{ padding: "3px", border: "1px solid white" }}>
                    <div className="green-cell">
                      {format1(selectedRegionData.total_funds_millions)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table
  style={{
    color: "grey",
    borderCollapse: "collapse",
    width: "90%",
    border: "1px solid white",
    marginBottom: "20px",
  }}
>
  <thead>
    <tr>
      <th style={{ padding: "8px", border: "1px solid white" }}>ATECO</th>
      <th style={{ padding: "8px", border: "1px solid white" }}>
        Quantita (000s)
      </th>
      <th style={{ padding: "8px", border: "1px solid white" }}>Spesi (€m)</th>
      <th style={{ padding: "8px", border: "1px solid white" }}>Medi (€)</th>
      <th
        className="hide-on-mobile"
        style={{ padding: "8px", border: "1px solid white" }}
      >
        Max (€)
      </th>
      <th style={{ padding: "8px", border: "1px solid white" }}>
        Mediani (€)
      </th>
      <th
        className="hide-on-mobile"
        style={{ padding: "8px", border: "1px solid white" }}
      >
        Deviazione (€k)
      </th>
    </tr>
  </thead>
  <tbody>
    {Object.entries(selectedRegionData.ATECO || {})
      .sort(([a], [b]) => a.localeCompare(b)) // Sort alphabetically
      .map(([atecoCode, metrics], index) => (
        <tr
          key={atecoCode}
          style={{
            color: "white",
            border: "1px solid white",
            backgroundColor: index % 2 === 0 ? "#FF5758" : "#4b7fe6", // Alternating row colors
          }}
        >
          <td style={{ border: "1px solid white", padding: "8px" }}>
            {`${atecoCode} - ${atecoDescriptions[atecoCode] || "Unknown"}`}
          </td>
          <td style={{ border: "1px solid white", padding: "8px" }}>
            {format1(metrics.grants_count || 0)}
          </td>
          <td style={{ border: "1px solid white", padding: "8px" }}>
            {format1(metrics.total_funds_millions)}
          </td>
          <td style={{ border: "1px solid white", padding: "8px" }}>
            {format1(metrics.avg_funding)}
          </td>
          <td
            className="hide-on-mobile"
            style={{ border: "1px solid white", padding: "8px" }}
          >
            {format1(metrics.max_funding)}
          </td>
          <td style={{ border: "1px solid white", padding: "8px" }}>
            {format1(metrics.median_funding)}
          </td>
          <td
            className="hide-on-mobile"
            style={{ border: "1px solid white", padding: "8px" }}
          >
            {formatThousands(metrics.std_dev_funding)}
          </td>
        </tr>
      ))}
  </tbody>
</table>

          </>
        ) : (
          <p>Scegli un regione a vedere le metriche.</p>
        )}
      </div>
    </div>
  );
}

export default RNA;
