import React, { useEffect, useState } from "react";
import { useCart } from "./cartContext.js";
import { Form, Field } from "react-final-form";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import "./checkout.css";
import { db, auth } from "..//..//src/config/firebase.js";
import { collection, getDocs, doc, getDoc, setDoc, arrayUnion} from "firebase/firestore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//import pluto from "..//..//src/pluto3.png";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  useStripe,
  useElements,
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
  Elements,
  CardElement,
  PaymentMethodMessagingElement,
} from "@stripe/react-stripe-js";
import Styles from "..//..//src/pages/Styles.js";
import Card from "./Card.js";
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || "/api";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

//console.log("Stripe Publishable Key:", process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`); 
//console.log("Stripe Publishable Key:", process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

//console.log("Stripe Promise:", stripePromise);

export default function Checkout() {
  const { cartItems } = useCart();
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const [cardholderName, setCardholderName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const location = useLocation(); 
  const { docId, paymentIntentId } = location.state || {};

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////server stuff
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  useEffect(() => {
    if (!paymentIntentId) {
      console.warn("No paymentIntentId found in location.state. Skipping check-order-status call.");
      return;
    }

    // Make sure your backend route supports POST at /api/check-order-status
    // and doesn't require a request body
    fetch(`${API_BASE_URL}/api/check-order-status?paymentIntentId=${paymentIntentId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Order status:", data.status);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [paymentIntentId, API_BASE_URL]);

  /////END OF SERVER STUFF
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // Initialize Stripe
    const initializeStripe = async () => {
      const stripe = await stripePromise;
      // You can use stripe object here if needed
    };
    initializeStripe();

  });

  useEffect(() => {
    const totalAmount = cartItems.reduce(
      (acc, item) => acc + (item.quantity || 1) * parseFloat(item.selectedPrice || 0), // Use selectedPrice here
      0
    );
    setTotal(totalAmount);
  }, [cartItems]);

  const handlePayment = () => {
    // Implement the logic to handle payment
    // After successful payment, you might want to navigate to a success page or clear the cart
    console.log("Proceed to payment");
    navigate("/payment-success"); // Adjust the route as necessary
  };

  if (cartItems.length === 0) {
    return <div>Your cart is empty.</div>;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////payment CHECKOUT FORM

  const CheckoutForm = (totalAmount) => {
    const stripe = useStripe();
    const elements = useElements();

    const handlePaymentSuccess = async () => {
      // Retrieve the purchased service data from local storage
      const purchasedService = JSON.parse(localStorage.getItem("purchasedService"));
      const user = auth.currentUser;
    
      if (user && purchasedService && purchasedService.id) {
        try {
          // Update only the 'service' field with the 'id' of the purchased service in the user's document
          const userRef = doc(db, "users", user.uid);
          await setDoc(
            userRef,
            { service: purchasedService.id }, // Only updating the 'service' field with the service id
            { merge: true }
          );
          //console.log("User's purchased service ID updated successfully.");
    
          // Remove the purchased service from local storage after updating Firebase
          localStorage.removeItem("purchasedService");
        } catch (error) {
          console.error("Error updating user's purchased service ID:", error);
        }
      }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
    
      if (!cardholderName) {
        alert("Please fill in your name.");
        return;
      }
    
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailAddress || !emailPattern.test(emailAddress)) {
        alert("Please enter a valid email address.");
        console.error("Invalid email address:", emailAddress);
        return;
      }
    
      if (!stripe || !elements) {
        console.error("Stripe or Elements is not initialized.");
        return;
      }
    
      let clientSecret, paymentIntentId;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/create-payment-intent`,
          { amount: total }
        );
        clientSecret = response.data.clientSecret;
        paymentIntentId = response.data.paymentIntentId;
        // console.log("Client Secret:", clientSecret);
        // console.log("PaymentIntent ID:", paymentIntentId);
      } catch (error) {
        console.error("Error creating PaymentIntent:", error);
        return;
      }
    
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        console.error("CardElement is not loaded or not available.");
        return;
      }
    
      try {
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: cardholderName,
              email: emailAddress,
            },
          },
        });
    
        if (result.error) {
          console.error("Payment failed:", result.error.message);
        } else if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
          //console.log("Payment succeeded:", result.paymentIntent);
          await handlePaymentSuccess();
          navigate("/paymentSuccess");
        } else {
          console.error("Unexpected payment status:", result.paymentIntent.status);
        }
      } catch (error) {
        console.error("Error confirming payment:", error);
      }
    };
    

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "#666EE8",
      color: "#000",
      fontWeight: "400",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: "18px", // Increase font size
      padding: "20px", // Add more padding
      "::placeholder": {
        color: "#aab7c4",
      },
      ":-webkit-autofill": {
        color: "#fce883",
      },
    },
    invalid: {
      color: "#fa755a",
    },
  },
  hidePostalCode: true, // Hide postal code for cleaner layout
};



    return (
      <div >
        <form onSubmit={handleSubmit}>
          <div >
          <div >
            <div >
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
            <div>
            <button
              type="submit"
             
              disabled={!stripe}
            >
              <span className="payment-info-checkout">Pay</span>
              <span className="total-price"> €{total.toFixed(2)}</span>
              {/* <svg
                className="lock-icon-checkout"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="16"
                height="16"
              >
                <path d="M12 2C9.243 2 7 4.243 7 7v3H5c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-2V7c0-2.757-2.243-5-5-5zm0 2c1.654 0 3 1.346 3 3v3H9V7c0-1.654 1.346-3 3-3zm-7 8h14v9H5v-9z" />
                <path d="M12 10c.552 0 1 .449 1 1v4c0 .551-.448 1-1 1s-1-.449-1-1v-4c0-.551.448-1 1-1zm0-1c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2s2-.897 2-2v-4c0-1.103-.897-2-2-2z" />
              </svg> */}
            </button>

            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div style={{ paddingBottom: '50px', maxWidth: '600px', margin: '0 auto' }}>
  <h1 style={{ textAlign: 'left', paddingLeft: "10px" }}>Checkout</h1>
  {cartItems.map((item, index) => (
    <div
      key={index}
      style={{
        padding: '20px',
        marginBottom: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
      }}
    >
      <h2>{item.serviceName} ({item.quantity || 1})</h2>
      <p>Prezzo: €{item.selectedPrice ? item.selectedPrice.toFixed(2) : 'N/A'}</p>
    </div>
  ))}

      {/* <button onClick={handlePayment}>Proceed to Payment</button> */}
      <div className="box-pay">
        Detagli:
        <div
          style={{
            border: "2px solid black",
            borderRadius: "5px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: "10px",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginLeft: "18px",
              marginTop: "14px",
            }}
          >
            <input
              className="email-address-name"
              style={{ fontSize: "14px" }}
              type="text"
              id="email-address"
              name="emailAddress"
              value={emailAddress}
              placeholder="Inserisci l'e-mail"
              onChange={(e) => setEmailAddress(e.target.value)}
              required
            />
            <input
              className="email-address-name"
              style={{ fontSize: "14px" }}
              type="text"
              id="cardholder-name"
              name="cardholderName"
              value={cardholderName}
              placeholder="Nome Cognome"
              onChange={(e) => setCardholderName(e.target.value)}
              required
            />
            <Elements stripe={stripePromise}>
              <div style={{width:"100%"}}>
              <div style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                {" "}
                Paga con la Carta
              </div>
              <div style={{paddingBottom: "100px"}}>
              <CheckoutForm />
              </div>
              </div>
              {/* <div className="other-options">Other options</div> */}
              {/* <PaymentMethodMessagingElement
                options={{
                  amount: Math.round(total * 100), // Convert total to cents
                  currency: "EUR",
                  // paymentMethodTypes: ["klarna"],
                  countryCode: "IT",
                }}
              /> */}
            </Elements>
          </div>
        </div>
      </div>
    </div>
  );
}
