///imports
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { db, auth } from "..//..//src/config/firebase.js";
import {
  getDocs,
  getDoc,
  collection,
  addDoc,
  serverTimestamp,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
  startAfter,
  onSnapshot,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const RNAmain = () => {
  const [aggregatedMetrics, setAggregatedMetrics] = useState({});
  const [topList, setTopList] = useState([]);
  const [filteredTopList, setFilteredTopList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "rnaMain"));
        let totalGrantsCountMain = 0;
        let totalFundsMillionsMain = 0;
        let maxFundingMain = Number.NEGATIVE_INFINITY;
        let minFundingMain = Number.POSITIVE_INFINITY;
        const fundingValues = [];
        const dataList = []; // List for all rows
  
        querySnapshot.docs.forEach((doc) => {
          const data = doc.data();
  
          // Map Firestore field names to desired keys
          const grants = data.grants || 0; // Firestore field: grants
          const funds = data.funds_in_millions || 0; // Firestore field: funds_in_millions
  
          totalGrantsCountMain += grants;
          totalFundsMillionsMain += funds;
  
          fundingValues.push(funds);
  
          if (funds > maxFundingMain) maxFundingMain = funds;
          if (funds < minFundingMain) minFundingMain = funds;
  
          // Add to full list
          dataList.push({
            id: doc.id,
            BASE_GIURIDICA_NAZIONALE:
              data.BASE_GIURIDICA_NAZIONALE || "Unknown",
            Titolo_list: data.Titolo_list || "Unknown",
            grants,
            funds,
            avg_funding: data.avg_funding || 0, // Fetch avg_funding
            min_funding: data.min_funding || 0, // Fetch min_funding
            max_funding: data.max_funding || 0, // Fetch max_funding
            median_funding: data.median_funding || 0, // Fetch median_funding
            std_dev_funding: data.std_dev_funding || 0, // Fetch std_dev_funding
          });
        });
  
        // Calculate median
        fundingValues.sort((a, b) => a - b);
        const midIndex = Math.floor(fundingValues.length / 2);
        const medianFundingMain =
          fundingValues.length % 2 === 0
            ? (fundingValues[midIndex - 1] + fundingValues[midIndex]) / 2
            : fundingValues[midIndex];
  
        // Calculate standard deviation
        const mean = totalFundsMillionsMain / fundingValues.length;
        const stdDevFundingMain = Math.sqrt(
          fundingValues.reduce(
            (sum, value) => sum + Math.pow(value - mean, 2),
            0
          ) / fundingValues.length
        );
  
        // Sort the list by grants in descending order
        dataList.sort((a, b) => b.grants - a.grants);
  
        // Set state with aggregated metrics and full list
        setAggregatedMetrics({
          totalGrantsCountMain,
          totalFundsMillionsMain,
          avgFundingMain: totalFundsMillionsMain / querySnapshot.docs.length,
          maxFundingMain:
            maxFundingMain === Number.NEGATIVE_INFINITY ? 0 : maxFundingMain,
          minFundingMain:
            minFundingMain === Number.POSITIVE_INFINITY ? 0 : minFundingMain,
          medianFundingMain,
          stdDevFundingMain,
        });
  
        setTopList(dataList); // Set full list for search
        setFilteredTopList(dataList); // Initialize filtered list
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    // Apply filter based on search term
    const filtered = topList.filter((item) =>
      item.Titolo_list.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTopList(filtered);
  }, [searchTerm, topList]);

  // Limit to 10 rows for display
  const displayedRows = filteredTopList.slice(0, 10);

  const navigate = useNavigate();

  return (
    <div style={{ marginLeft: "10px" }}>
       <button
      onClick={() => navigate("/incentivoAnalysis")}
      style={{
        padding: "10px 20px",
        backgroundColor: "#4CAF50", // Green background
        color: "white", // White text
        border: "none", // No border
        borderRadius: "5px", // Rounded corners
        cursor: "pointer", // Pointer on hover
        fontSize: "16px",
      }}
    >
      Vai all'analisi degli incentivi
    </button>
       <h2 style={{ padding: "10px", color: "#FF5758" }}>
    Cercare Incentivo
    </h2>
      {/* Aggregated Metrics Table */}
      {/* <table style={{ margin: "10px" }}>
        <thead>
          <tr>
          <th style={{ padding: "6px" }}>Quantita (000s)</th>
                  <th style={{ padding: "6px" }}>Spesi (€m)</th>
                  <th style={{ padding: "6px" }}>Medi (€k)</th>
                  <th style={{ padding: "6px" }}>Max (€k)</th>
                  <th style={{ padding: "6px" }}>Mediani (€k)</th>
                  <th style={{ padding: "6px" }}>Deviazione (€k)</th>
          </tr>
        </thead>
        <tbody>
  <tr>
    <td className="green-cell" style={{ padding: "3px", border: "1px solid white" }}>
      {aggregatedMetrics?.totalGrantsCountMain || 0}
    </td>
    <td className="green-cell" style={{ padding: "3px", border: "1px solid white" }}>
      {aggregatedMetrics?.totalFundsMillionsMain != null
        ? aggregatedMetrics.totalFundsMillionsMain.toFixed(2)
        : "-"}
    </td>
    <td className="green-cell" style={{ padding: "3px", border: "1px solid white" }}>
      {aggregatedMetrics?.avgFundingMain != null
        ? aggregatedMetrics.avgFundingMain.toFixed(0)
        : "-"}
    </td>
    <td className="green-cell" style={{ padding: "3px", border: "1px solid white" }}>
      {aggregatedMetrics?.maxFundingMain != null
        ? aggregatedMetrics.maxFundingMain.toFixed(0)
        : "-"}
    </td>
    <td className="green-cell" style={{ padding: "3px", border: "1px solid white" }}>
      {aggregatedMetrics?.medianFundingMain != null
        ? aggregatedMetrics.medianFundingMain.toFixed(0)
        : "-"}
    </td>
    <td className="green-cell" style={{ padding: "3px", border: "1px solid white" }}>
      {aggregatedMetrics?.stdDevFundingMain != null
        ? aggregatedMetrics.stdDevFundingMain.toFixed(0)
        : "-"}
    </td>
  </tr>
</tbody>

</table> */}
<div style={{ margin: "20px", width: "50%"}}>
  <input
    type="text"
    placeholder="Cercare Titolo"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />
</div>
<table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
  <thead>
    <tr style={{ backgroundColor: "white", color: "grey" }}>
      <th style={{ padding: "16px", border: "1px solid white" }}>
        BASE GIURIDICA
      </th>
      <th style={{ padding: "16px", border: "1px solid white" }}>TITOLO</th>
      <th style={{ border: "1px solid white" }}>Qta</th>
      <th style={{ border: "1px solid white" }}>Spesi (€m)</th>
      <th style={{ padding: "6px", border: "1px solid white" }}>Medi (€)</th>
      <th className="hide-on-mobile" style={{ padding: "6px", border: "1px solid white" }}>Max (€)</th>
      <th className="hide-on-mobile" style={{ padding: "6px", border: "1px solid white" }}>Min (€)</th>
      <th style={{ padding: "6px", border: "1px solid white" }}>Mediani (€)</th>
      <th className="hide-on-mobile" style={{ padding: "6px", border: "1px solid white" }}>
        Deviazione (€)
      </th>
    </tr>
  </thead>
  <tbody>
    {displayedRows.map((item, index) => (
      <tr
        key={item.id}
        style={{
          backgroundColor: index % 2 === 0 ? "#FF5758" : "#4b7fe6",
          color: "white",
          fontSize: "12px",
        }}
      >
        <td style={{ border: "1px solid white", padding: "8px" }}>
          {item.BASE_GIURIDICA_NAZIONALE.length > 100
            ? `${item.BASE_GIURIDICA_NAZIONALE.slice(0, 100)}...`
            : item.BASE_GIURIDICA_NAZIONALE}
        </td>
        <td style={{ border: "1px solid white", padding: "8px" }}>
          {item.Titolo_list.length > 100
            ? `${item.Titolo_list.slice(0, 100)}...`
            : item.Titolo_list}
        </td>
        <td style={{ border: "1px solid white", padding: "8px" }}>
          {item.grants}
        </td>
        <td style={{ border: "1px solid white", padding: "8px" }}>
          {item.funds.toFixed(1)}
        </td>
        <td style={{ border: "1px solid white", padding: "8px" }}>
          {item.avg_funding?.toFixed(0)}
        </td>
        <td className="hide-on-mobile" style={{ border: "1px solid white", padding: "8px" }}>
          {item.max_funding?.toFixed(0)}
        </td>
        <td className="hide-on-mobile" style={{ border: "1px solid white", padding: "8px" }}>
          {item.min_funding?.toFixed(0)}
        </td>
        <td style={{ border: "1px solid white", padding: "8px" }}>
          {item.median_funding?.toFixed(0)}
        </td>
        <td className="hide-on-mobile" style={{ border: "1px solid white", padding: "8px" }}>
          {item.std_dev_funding?.toFixed(0)}
        </td>
      </tr>
    ))}
  </tbody>
</table>


  

      <p>
        Showing {displayedRows.length} of {filteredTopList.length} results
      </p>
    </div>
  );
};

export default RNAmain;
