import React, { useState, useEffect, useRef, useCallback } from "react";
import { auth, googleProvider, db } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  doc,
  setDoc,
  collection,
  getDocs,
  addDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Users from "./users.js";
import { useAuth } from "../../src/components/auth-email.js";
import ToggleSwitchSearch from "../components/togglesearch.js";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  LineChart,
} from "recharts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//../src/components/userPrompt.js";
import LoadingBar from "..//../src/components/taskStatus.js";
import thumbup from "..//../src/thumbup.svg";
import thumbdown from "..//../src/thumbdown.svg";
import rightarrow from "..//../src/right_arrow.svg";
import { Spinner } from "react-bootstrap";
import { FaUser, FaTimes, FaFilter } from "react-icons/fa";
import Timeline from "..//../src/components/timeline.js";


function IncentiveSearchCommercialista() {
  const { currentUser } = useAuth(); // Using a custom hook to get the current user
  const [atecoL3, setAtecoL3] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyDeminimis, setCompanyDeminimis] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState("");
  const [Descrizione, setDescrizione] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [incentives, setIncentives] = useState("");
  const [documents, setDocuments] = useState([]);
  const [incentivesCount, setIncentivesCount] = useState(0);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [incentivesData, setIncentivesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(true);
  const [isLoadingFunding, setIsLoadingFunding] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [cw, setCw] = useState(""); // Empty string as initial value or a default value "0" or "1"
  const [lastDataUpdate, setLastDataUpdate] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [newpiva, setNewpiva] = useState("");
  const [refreshData, setRefreshData] = useState(false); // State to trigger re-render
  const [dataChangeTrigger, setDataChangeTrigger] = useState(0);
  const [pivas, setPivas] = useState([]);
  const [selectedRegione, setSelectedRegione] = useState("");
  const [selectedComune, setSelectedComune] = useState("");
  const [comune, setComune] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [goals, setGoals] = useState([]);
  const [companyServices, setCompanyServices] = useState("");
  const [showFiltersGroups, setShowFiltersGroups] = useState(false);
  const [showFiltersProfile, setShowFiltersProfile] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedPivaData, setSelectedPivaData] = useState(null);
  const [commUserId, setCommUserId] = useState("");
  const [selectedPivaId, setSelectedPivaId] = useState(null);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const buttonRef = useRef(null);
  const [sortBy, setSortBy] = useState("");
  const [isAscending, setIsAscending] = useState({ openingDate: false, closingDate: false });
  const [taskId, setTaskId] = useState(null);
  const requiredServices = ["kEmew7Mg5Gx0I7f3DM0a", "cxzmelrUTmAt04kQcOUq"];

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  //// GET USER INFO

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (user) => {
    if (user && user.email) {
      const usersCollectionRef = collection(db, "users");
      const q = query(usersCollectionRef, where("email", "==", user.email));
      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setFirstName(userData.firstName);
          setCompanyServices(userData.service || []);
          //console.log("companyServices:", userData.service);
        } else {
          console.log("No user data found for email:", user.email);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  // Run on currentUser change
  useEffect(() => {
    if (currentUser) {
      fetchUserData(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (companyServices && companyServices.length > 0) {
      const hasRequiredService = requiredServices.some((service) =>
        companyServices.includes(service)
      );
      setIsFilterEnabled(hasRequiredService);
      //console.log("isFilterEnabled:", hasRequiredService);
    } else {
      setIsFilterEnabled(false);
    }
  }, [companyServices]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// APIS: add new piva and confirm goals flows

  const removeUndefinedFields = (obj) => {
    return Object.entries(obj)
      .filter(([_, value]) => value !== undefined)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  };

  const updateCommDb = async () => {
    //console.log("Button clicked to update CommDb");
    setLoading(true);
    setSuccess(false);
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        const pivaArray = newpiva.split(",").map((piva) => piva.trim());
        const commercialistaCollection = collection(db, "commercialista");

        // Check if adding new PIVAs exceeds the maximum allowed
        const q = query(
          commercialistaCollection,
          where("commercialista", "==", user.uid)
        );
        const snapshot = await getDocs(q);
        const existingPivasCount = snapshot.docs.length;

        if (existingPivasCount + pivaArray.length > 10) {
          console.error("Too many PIVAs requested, maximum available = 10");
          alert("Troppi PIVA richiesti, massimo disponibile = 10");
          setLoading(false);
          return;
        }

        // Loop to add new PIVAs
        for (const piva of pivaArray) {
          // Check if the PIVA already exists for this user
          const existingPivaQuery = query(
            commercialistaCollection,
            where("piva", "==", piva),
            where("commercialista", "==", user.uid)
          );
          const existingPivaSnapshot = await getDocs(existingPivaQuery);

          // If the PIVA already exists, skip adding it again
          if (!existingPivaSnapshot.empty) {
            console.log("PIVA already exists for this user, skipping:", piva);
            continue;
          }

          // Add new document if it does not exist
          const docRef = await addDoc(commercialistaCollection, {
            piva,
            regione: selectedRegione,
            comune: selectedComune,
            timestamp: new Date(),
            commercialista: user.uid,
          });

          //console.log("Document added with ID:", docRef.id);

          // Send additional PIVA data to backend
          const apiResponse = await sendPivaToBackend(
            piva,
            docRef.id,
            selectedRegione,
            selectedComune
          );
          //console.log("Backend response:", apiResponse);

          if (apiResponse.status === "success") {
            // Clean apiResponse.data by removing undefined fields
            const cleanedData = removeUndefinedFields(apiResponse.data);

            // Merge cleaned data into Firestore document
            await setDoc(doc(db, "commercialista", docRef.id), cleanedData, {
              merge: true,
            });

            setSuccess(true);
          } else {
            throw new Error(
              "API data processing failed: " + apiResponse.message
            );
          }
        }
        setLoading(false);
      } catch (error) {
        console.error(
          "Error updating Firestore or communicating with the backend:",
          error
        );
        setLoading(false);
      }
    } else {
      console.error("No user is signed in.");
    }
  };

  

  //////////////////////////////////////////////////////////////////////////////////////////////
  //// SendPivaToBackend: this triggers the OpenAPI & Filtered Data for New PIVAs
  //// SendPivaToBackendforConfirm: this triggers the first part of the backend to prepare the data
  //// ProcessGoals: this triggers the Goals Confermare

  const sendPivaToBackend = async (piva, docId, regione, comune) => {
    //console.log("Sending piva to backend:", piva);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    try {
      const payload = { piva, docId, regione, comune };
      const response = await fetch(`${API_BASE_URL}/api/propiva`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const responseData = await response.json();
      console.log("Successfully sent piva to backend:", responseData);
      return responseData;
      
    } catch (error) {
      console.error("Error sending piva to backend:", error);
      return { status: "error", message: error.message };
    }
  };

  const pollTaskStatus = async (taskId, onSuccess, onError) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
    const interval = setInterval(async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/piva-status/${taskId}`);
        const data = await response.json();
  
        if (data.status === "success") {
          clearInterval(interval); // Stop polling
          onSuccess(data.data); // Call success callback with result data
        } else if (data.status === "error") {
          clearInterval(interval); // Stop polling
          onError(data.message); // Call error callback with error message
        }
      } catch (error) {
        console.error("Error checking task status:", error);
        clearInterval(interval); // Stop polling on error
        onError("Failed to check task status.");
      }
    }, 3000); // Poll every 3 seconds
  };
  

  const sendPivaToBackendforConfirm = async (piva, docId) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

      // Log the data being sent to the backend
      console.log("Sending to backend:", {
        piva: selectedPivaData.piva,
        docId: selectedPivaData.id,
      });

      const response = await fetch(`${API_BASE_URL}/api/pivaconfirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          piva: selectedPivaData.piva,
          docId: selectedPivaData.id,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch incentives");
      }

      // Log the successful response
      console.log("Incentives fetched successfully:", data);
    } catch (error) {
      // Log any errors
      console.error("Error fetching incentives:", error);
    }
  };

  const processGoals = async () => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/processgoals`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ piva: selectedPivaData.piva }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to process goals");
      }

      //console.log("Goals processed successfully:", data);
      alert("Obiettivi elaborati con successo!");
    } catch (error) {
      console.error("Error processing goals:", error);
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //////// END OF API FLOW
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////// RENDER FIREBASE

  /////// Fetch Order
  useEffect(() => {
    if (selectedPivaData && selectedPivaData.id) {
      fetchGoals();
    }
  }, [selectedPivaData]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  //// Display the aggregate results (all the businesses) and ability to filter for 1 piva

  useEffect(() => {
    if (!currentUser) return;

    const commercialistaCollection = collection(db, "commercialista");
    const q = query(
      commercialistaCollection,
      where("commercialista", "==", currentUser.uid)
    );

    // Set up real-time listener
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const pivaList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPivas(pivaList);

        // Automatically select the first PIVA if available
        if (pivaList.length > 0) {
          handleRowSelect2(pivaList[0]);
        }

        setLoading(false);
      },
      (error) => {
        console.error("Error fetching PIVAs:", error);
        setLoading(false);
      }
    );

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [currentUser, db]);

  const uniquePivas = new Set(pivas.map((piva) => piva.piva));

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////table selector

  const handleRowSelect2 = (piva) => {
    setSelectedPivaData(piva);
    fetchIncentivesData(piva.id); // Fetch incentives data for the selected PIVA
    setAtecoL3(piva.companyAteco);
    setRegione(piva.regione);
    setCompanySize(piva.companySize);
    setCompanyDeminimis(piva.companyDeminimis);
    setCompanyName(piva.companyName);
    setCompanyTurnover(piva.companyTurnover);
    setCompanyEmployees(piva.companyEmployees);
    setSelectedPivaId(piva.id);
  };

  const [filters, setFilters] = useState({
    CW: "",
    Agevolazione: "",
    Fondo_perduto: "",
    Capitale_di_rischio: "",
    Prestito: "",
    Interventi_garanzia: "",
    Anticipo_rimborsabile: "",
    hyperFilter: "",
    strategy: "",
    titolo: "",
    level: "",
    thumb: "",
    searchKeyword: "",
  });

  const [filteredCount, setFilteredCount] = useState(0);

  // Function to handle filter changes
  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        [field]: value, // Dynamically update the specific field
      };
      //console.log("Updated Filters:", newFilters); // Log the updated filters
      fetchIncentivesData(selectedPivaData.id, newFilters); // Fetch data with updated filters
      return newFilters;
    });
  };

  // Fetch incentives when component mounts or selectedPivaData changes
  useEffect(() => {
    if (selectedPivaData && selectedPivaData.id) {
      setLoading(true);
      fetchIncentivesData(selectedPivaData.id, filters);
    }
  }, [selectedPivaData]);

  // Updated fetchIncentivesData function
  const fetchIncentivesData = async (pivaId, filters = {}) => {
    try {
      const incentivesDocRef = doc(db, "filteredData", pivaId);
      const incentivesDoc = await getDoc(incentivesDocRef);

      if (incentivesDoc.exists()) {
        const data = incentivesDoc.data();
        let incentivesData = Array.isArray(data.incentives)
          ? data.incentives
          : [];

        // Apply the filters, including searchKeyword, to the incentivesData array
        const matchingIncentives = incentivesData.filter((incentive) => {
          let matches = true;
          
          for (const [filterField, filterValue] of Object.entries(
            filters || {}
          )) {
            if (filterValue) {
              if (filterField === "searchKeyword") {
                const titolo = incentive.Titolo || "";
                if (!titolo.toLowerCase().includes(filterValue.toLowerCase())) {
                  matches = false;
                  break;
                }
              } else if (filterField === "level") {
                // Match the 'Level' field in Firebase
                const incentiveField = incentive.Level || ""; // Default to an empty string if missing
                if (incentiveField !== filterValue) {
                  matches = false;
                  break;
                }
              } else {
                const incentiveField = incentive[filterField] || "N"; // Default to "N" if missing
                if (incentiveField !== filterValue) {
                  matches = false;
                  break;
                }
              }
            }
          }

          return matches;
        });

        const incentiveIds = matchingIncentives.map(
          (item) => item.ID_Incentivo
        );

        const incentiveDetails = await Promise.all(
          incentiveIds.map(async (id) => {
            const incentiveRef = doc(db, "incentivi", id);
            const incentiveDoc = await getDoc(incentiveRef);
            if (incentiveDoc.exists()) {
              return {
                ...incentiveDoc.data(),
                ID_Incentivo: id,
                filtered: 1,
              };
            }
            return null;
          })
        );

        // Filter out any null values
        const mappedIncentives = incentiveDetails.filter(Boolean);

        // Update the state with fetched incentives
        setIncentives(mappedIncentives);

        // Update filteredCount
        setFilteredCount(mappedIncentives.length);
      } else {
        //console.log("No incentives data found for this PIVA.");
        setIncentives([]);
      }
    } catch (error) {
      //console.error("Error fetching incentives data:", error);
      setIncentives([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSortChange = (criteria) => {
    setSortBy(criteria);
  };

  const handleSortToggle = (field) => {
    const newOrder = !isAscending[field];
    setIsAscending((prevState) => ({
      ...prevState,
      [field]: newOrder,
    }));
    setSortBy(`${field}${newOrder ? "Asc" : "Desc"}`);
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  /// Navigation Part: Navigate to ThreadUnic
  const navigate = useNavigate();
  const [threadId, setThreadId] = useState("");
  const [currentThreadId, setCurrentThreadId] = useState(null);

  const startNewConversation = async (
    atecoL3,
    regione,
    assistant,
    incentiveId,
    firstName,
    companyName,
    companySize,
    companyEmployees,
    companyTurnover
  ) => {
    if (!selectedPivaData) {
      alert("Please select a piva before starting a new conversation.");
      return;
    }
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = `Ciao, e questo incentivo buona per nostra azienda? Io sono ${firstName} e l'azienda si chiama ${companyName}. Siamo in ${regione} con ateco: ${atecoL3}. Siamo un ${companySize} con ${companyEmployees} dipendenti e turnover annuale di €${companyTurnover}.`;
      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: userMessage }),
      });
      //console.log("API Base URL:", API_BASE_URL);
      // console.log("firstName:", firstName);
      // console.log("companyName:", companyName);
      // console.log("firstName:", regione);
      // console.log("message:", userMessage);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log("Response data:", data); // Log the entire response data
      localStorage.setItem("currentThreadId", data.thread_id);
      //console.log("Assistant parameter:", assistant);
      //console.log("Stored thread_id in localStorage:", data.thread_id);

      if (data.thread_id && assistant) {
        //console.log("New thread ID:", data.thread_id, "assistant:", assistant);
        const title = `Discussion on ATECO ${atecoL3} in ${regione}`;
        window.scrollTo(0, 0);
        await fetchGoals();
        // Navigate to the threadUnic page with the thread ID and assistant ID in the URL
        navigate(`/threadUnic/${assistant}/${data.thread_id}/${incentiveId}`, {
          state: { title: title },
        });
      } else {
        console.error(
          "Thread ID or Assistant ID was not returned from the server."
        );
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const updateThumbs = async (incentiveId, thumbsValue) => {
    if (!currentUser || !currentUser.uid) {
      console.error("User is not authenticated or UID is missing");
      return; // Do nothing if user is not authenticated
    }

    console.log("Sending thumbs update:", {
      userId: currentUser.uid, // Use currentUser.uid instead of currentUser.id
      incentiveId,
      thumbsValue,
    }); // Log the values being sent

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/updatethumbs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: currentUser.uid, // Ensure this is the actual user UID
          incentiveId, // This should be the actual incentive ID
          thumbsValue, // This should be 'Y' or 'N'
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Update successful:", data.message);
      } else {
        console.error("Error updating thumbs:", data.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  const nextSectionRef = useRef(null);
  const scrollToNextSection = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////// Goals

  const [goalInput, setGoalInput] = useState("");
  const [isGoalInputVisible, setIsGoalInputVisible] = useState(false);
  const handleGoalSubmit = async (event, pivaId) => {
    // Determine the type of event
    const isEnterKey = event.type === "keydown" && event.key === "Enter";
    const isClickEvent = event.type === "click";

    // Proceed only if Enter key is pressed or the event is a click
    if ((isEnterKey || isClickEvent) && goalInput.trim()) {
      try {
        // Log the entire selectedPivaData object to verify its structure and current state
        //console.log("Selected PIVA Data:", selectedPivaData);

        // Use the docName passed as a parameter or fallback to selectedPivaData.docName
        const docNameToUse = pivaId || selectedPivaData.id;
        //console.log("Using docName for goals document:", docNameToUse);

        // Check if docName is valid
        if (!docNameToUse) {
          console.error("Document name is not set. Cannot add goal.");
          return; // Early exit if docName is not valid
        }

        // Reference the "goals" collection
        const goalsCollectionRef = collection(db, "goals");
        //console.log("Goals Collection Reference:", goalsCollectionRef);

        // Create a document reference using docName
        const goalDocRef = doc(goalsCollectionRef, docNameToUse);
        //console.log("Goal Document Reference:", goalDocRef.path);

        // Fetch the document to check if it exists
        const goalDoc = await getDoc(goalDocRef);
        //console.log("Does Goal Document Exist?", goalDoc.exists());

        if (goalDoc.exists()) {
          //console.log("Existing Goals:", goalDoc.data().goals);
          // Document exists, add goal to the goals array
          await updateDoc(goalDocRef, {
            goals: arrayUnion(goalInput),
          });
          //console.log(`Added goal "${goalInput}" to existing document "${docNameToUse}".`);
        } else {
          // Document does not exist, create a new one with the goals array
          await setDoc(goalDocRef, {
            goals: [goalInput], // Initialize with the first goal
          });
          console.log(
            `Created new document "${docNameToUse}" with goal "${goalInput}".`
          );
        }

        // Log the goal added
        //console.log("Goal added:", goalInput);

        // Refresh goals list
        await fetchGoals();

        // Clear the input and hide it
        setGoalInput(""); // Clear the input after submission
        setIsGoalInputVisible(false); // Hide the input after submission
        toggleVisibility();
      } catch (error) {
        console.error("Error adding goal:", error);
      }
    }
  };

  const [currentGoals, setCurrentGoals] = useState([]); // State for holding the current goals

  const fetchGoals = useCallback(async () => {
    if (!selectedPivaData) {
      console.log("No selected PIVA data to fetch goals for.");
      return;
    }

    try {
      const goalsCollectionRef = collection(db, "goals");
      const goalDocRef = doc(goalsCollectionRef, selectedPivaData.id); // Use the selected PIVA's ID

      // Fetch the document for goals
      const goalDoc = await getDoc(goalDocRef);
      if (goalDoc.exists()) {
        const goalData = goalDoc.data();
        // Set the goals in state
        setCurrentGoals(goalData.goals || []); // Initialize to an empty array if no goals exist
        //console.log("Fetched Goals:", goalData.goals);
      } else {
        //console.log("No goals found for this document.");
        setCurrentGoals([]); // Set to an empty array if no document exists
      }
    } catch (error) {
      console.error("Error fetching goals:", error);
    }
  }, [selectedPivaData]);

  const extractBase = (ateco) => {
    if (typeof ateco !== "string") {
      console.error("Invalid input: ateco must be a string");
      return "";
    }
    // Regex to capture two digits before and one or two digits after the decimal point
    const numericalPartMatch = ateco.match(/^(\d{2}\.\d{1,2})/);
    let numericalPart = "";

    if (numericalPartMatch) {
      numericalPart = numericalPartMatch[1];
      const decimalParts = numericalPart.split(".");

      // Ensure two decimal places by padding with a zero if necessary
      if (decimalParts[1].length === 1) {
        numericalPart = `${decimalParts[0]}.${decimalParts[1]}0`;
      }
    }

    return numericalPart;
  };

  const handleRemoveGoal = async (goal) => {
    if (!selectedPivaData?.id) {
      console.error("Document name is not set. Cannot remove goal.");
      return;
    }

    const docNameToUse = selectedPivaData.id;
    const db = getFirestore();
    const docRef = doc(db, "goals", docNameToUse);

    try {
      // Optimistically update the local state
      setCurrentGoals((prevGoals) => prevGoals.filter((g) => g !== goal));

      // Make "Confermare" button visible immediately
      toggleVisibility();

      // Remove the goal from Firestore
      await updateDoc(docRef, {
        goals: arrayRemove(goal), // Use Firestore's arrayRemove to remove the goal
      });

      // Trigger backend fetch
      await sendPivaToBackendforConfirm(
        selectedPivaData.piva,
        selectedPivaData.id
      );

      //console.log(`Goal '${goal}' removed successfully.`);
    } catch (error) {
      console.error("Error removing goal:", error);

      // Rollback local state if Firestore update fails
      setCurrentGoals((prevGoals) => [...prevGoals, goal]);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  //////// START OF FORMATTING

  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim(); // Trim whitespace at the start and end

      // Remove everything before and including the first colon on the first line
      desc = desc.replace(/^[^:]*:\s*/, "");

      // Normalize new lines and handle the initial bullet point
      let lines = desc.split(/\n+/); // Split the description into lines
      lines = lines.reduce((filteredLines, line, index) => {
        line = line.trim(); // Trim each line to remove leading/trailing whitespace

        // Skip empty lines to avoid adding extra spaces
        if (line === "") {
          return filteredLines;
        }

        // Handle numbered lines to not start with a bullet
        if (/^\d+\./.test(line)) {
          filteredLines.push(line); // Return the line as is if it starts with a number and a period
        } else if (index > 0 && filteredLines.length > 0) {
          // Apply bullets to lines that are not the first line and not after an empty line
          filteredLines.push("• " + line);
        } else {
          filteredLines.push(line); // First line or any line not fitting the above criteria gets no bullet
        }

        return filteredLines;
      }, []);

      // Join the lines back together, inserting a newline between each
      return lines.join("\n");
    }
    return "No description available"; // Return a default message if no description is provided
  };

  const getCategoryClass = (category) => {
    const categoryClasses = {
      "Crisi d'impresa": "crisi-impresa",
      Digitalizzazione: "digitalizzazione",
      "Imprenditoria femminile": "imprenditoria-femminile",
      "Innovazione e ricerca": "Innovazione-ricerca",
      "Sostegno investimenti": "Sostegno-investimenti",
      "Sostegno liquidità": "Sostegno-liquid",
      "Start up/Sviluppo d'impresa": "Startup-impresa",
      "Crisi d'impresa": "Crisi-impresa",
      "Inclusione sociale": "Inclusione-sociale",
      "Imprenditoria giovanile": "Imprenditoria-giovanile",
      Internazionalizzazione: "Internazionalizzazione",
      "Transizione ecologica": "Transizione-ecologica",
      // Add more mappings here...
    };
    return categoryClasses[category] || "default-category"; // Fallback class
  };

  const formatDate = (dateString) => {
    return dateString
      ? dateString.split("T")[0]
      : "Aperto fino a nuova communicazione"; // Default to 'N/A' if dateString is undefined
  };

  const placeholders = [
    "SMART&START",
    "PNRR",
    "CREDITO",
    "INTERNAZIONALE",
    "INFRASTRUTTURE",
    "TASSO ZERO",
    "AGEVOLAZIONI",
  ];

  const newEventRegioneOptions = [
    "Abruzzo",
    "Aosta",
    "Basilicata",
    "Calabria",
    "Campania",
    "Emilia Romagna",
    "Friuli Venezia Giulia",
    "Lazio",
    "Liguria",
    "Lombardia",
    "Marche",
    "Molise",
    "Piemonte",
    "Puglia",
    "Sardinia",
    "Sicilia",
    "Trentino/Alto Adige/Sudtirol",
    "Toscana",
    "Umbria",
    "Veneto",
  ];

  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      // Update the placeholder text by cycling through the placeholders array
      currentIndex = (currentIndex + 1) % placeholders.length;
      setCurrentPlaceholder(placeholders[currentIndex]);
    }, 1000); // Change placeholder every 2000 milliseconds (2 seconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  /*regione*/
  const handleRegionChange = (e) => {
    const selectedOption = e.target.value;

    // This will set the region to Lombardia if selected, or directly to the chosen option otherwise.
    setSelectedRegione(selectedOption);
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleItemClick = (titolo) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchKeyword: titolo, // Set the clicked item's Titolo as the searchKeyword
    }));
    setIsDropdownVisible(false); // Hide the dropdown
  };

  const searchContainerRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false); // Hide the dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [levels, setLevels] = useState([
    "Nazionale",
    "Regionale",
    "Provincia",
    "Locale",
    "Altro",
  ]);

  const getLevelColor = (level) => {
    switch (level) {
      case "Nazionale":
        return "#092f7a"; // Red
      case "Regionale":
        return "#d44747"; // Green
      case "Provincia":
        return "#ff8500"; // Blue
      case "Locale":
        return "#FF33A6"; // Pink
      case "Altro":
        return "#AAAAAA"; // Gray
      default:
        return "#000000"; // Black
    }
  };

  const [showDescription, setShowDescription] = useState(false);

  const calculateDaysLeft = (date) => {
    const today = new Date();
    const targetDate = new Date(date);
    const difference = Math.ceil((targetDate - today) / (1000 * 60 * 60 * 24));
    return difference > 0 ? ` tra ${difference} giorni` : "-";
  };

  document.querySelectorAll(".incentivy-container-comm3 ul").forEach((ul) => {
    const items = ul.querySelectorAll("li");
    if (items.length === 1) {
      ul.classList.add("single-item");
      ul.classList.remove("double-item");
    } else if (items.length === 2) {
      ul.classList.add("double-item");
      ul.classList.remove("single-item");
    } else {
      ul.classList.remove("single-item", "double-item");
    }
  });
  
  const sortIncentives = (incentives, sortBy) => {
    return [...incentives].sort((a, b) => {
      if (sortBy === "openingDateAsc") {
        return new Date(a.Data_apertura) - new Date(b.Data_apertura);
      } else if (sortBy === "openingDateDesc") {
        return new Date(b.Data_apertura) - new Date(a.Data_apertura);
      } else if (sortBy === "closingDateAsc") {
        return new Date(a.Data_chiusura) - new Date(b.Data_chiusura);
      } else if (sortBy === "closingDateDesc") {
        return new Date(b.Data_chiusura) - new Date(a.Data_chiusura);
      }
      return 0; // No sorting if sortBy is not set
    });
  };

  const sortedIncentives = sortIncentives(incentives, sortBy);

  const [visibleCount, setVisibleCount] = useState(6);
  const visibleIncentives = sortedIncentives.slice(0, visibleCount);
  
  const handleReadMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };
  
  

  /////// END OF FORMATTING
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  ////// START OF JSX

  return (
    <div className="searchy-container-comm">
      {!isAuthenticated && <UserPromptModal />}
      <h1 className="incentivy-container-comm2">Multi Partita IVA Search </h1>
{/*       <div>
      {isLoading && <LoadingBar isLoading={isLoading} />}
      <button onClick={updateCommDb}>Update CommDb</button>
      {success && <p style={{ color: "green" }}>Update completed successfully!</p>}
      {!success && isLoading === false && <p style={{ color: "red" }}>Failed to update CommDb.</p>}
    </div> */}
      <div style={{ backgroundColor: "black" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "30px",
            gap: "40px",
          }}
        >
          <button
            className="button-searchintroi"
            onClick={() => setShowFiltersProfile((prev) => !prev)}
          >
            {showFiltersProfile ? (
              <>
                <FaTimes /> PROFILO
              </>
            ) : (
              <>
                <FaUser /> PROFILO
              </>
            )}
          </button>

          <button
            className="button-searchintroi"
            onClick={() => setShowFiltersGroups((prev) => !prev)}
          >
            {showFiltersGroups ? (
              <>
                <FaTimes /> FILTRI
              </>
            ) : (
              <>
                <FaFilter /> FILTRI
              </>
            )}
          </button>
        </div>
        {showFiltersProfile && (
          <div>
            <div className="piva-container-comm">
              <button
                onClick={toggleVisibility}
                style={{ marginBottom: "10px", marginLeft: "15px" }}
                className="logout-button"
              >
                {isVisible ? "-" : "+"} Partita IVA
              </button>

              {isVisible && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="container-searchi"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      marginBottom: "10px",
                      padding: "5px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>Partita IVA:</label>
                    <input
                      className="select-dropdown-searchij"
                      type="text"
                      placeholder="12345678901"
                      value={newpiva}
                      onChange={(e) => setNewpiva(e.target.value)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "5px",
                      }}
                    />
                  </div>

                  <div
                    className="container-searchi"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      marginBottom: "10px",
                      padding: "5px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>Comune:</label>
                    <input
                      className="select-dropdown-searchij"
                      type="text"
                      placeholder="Monza"
                      value={selectedComune}
                      onChange={(e) => setSelectedComune(e.target.value)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        padding: "5px",
                      }}
                    />
                  </div>

                  <div
                    className="container-searchi"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      padding: "5px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>Regione:</label>
                    <select
                      className="select-dropdown-searchij"
                      value={selectedRegione}
                      onChange={handleRegionChange}
                      style={{ flex: 1 }}
                    >
                      <option value="defaultRole">Sceglie un regione</option>
                      {newEventRegioneOptions.map((regione, index) => (
                        <option key={index} value={regione}>
                          {regione}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <button
                      type="button"
                      onClick={updateCommDb}
                      style={{
                        background: "#092f7a",
                        color: "white",
                        border: "1px solid white",
                        borderRadius: "5px",
                        padding: "10px 20px",
                        cursor: "pointer",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                      disabled={loading} // Disable button while loading
                    >
                      {loading ? "Processing..." : "INVIA"}
                    </button>

                    {loading && (
                      <div className="spinner" style={{ marginTop: "10px" }}>
                        {/* Spinner style */}
                        <div
                          style={{
                            width: "24px",
                            height: "24px",
                            border: "4px solid #ccc",
                            borderTop: "4px solid #092f7a",
                            borderRadius: "50%",
                            animation: "spin 1s linear infinite",
                          }}
                        />
                      </div>
                    )}

                    {success && (
                      <div
                        className="success-popup"
                        style={{
                          marginTop: "10px",
                          padding: "10px",
                          backgroundColor: "#d4edda",
                          color: "#155724",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        Aggiornamento completato con successo!
                      </div>
                    )}

                    {/* Spinner animation CSS */}
                    <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
                  </div>
                </div>
              )}
            </div>
            <div className="agent2-containers-comm">
              <div className="incentivy-container-comm">
                <div>
                  <h2>Portfolio: {uniquePivas.size} imprese</h2>
                  {loading ? (
                    <p>Loading PIVAs...</p>
                  ) : pivas.length > 0 ? (
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        border: "1px solid white",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              color: "white",
                              border: "1px solid white",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            PIVA
                          </th>
                          <th
                            style={{
                              color: "white",
                              border: "1px solid white",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Nome
                          </th>
                          <th
                            style={{
                              color: "white",
                              border: "1px solid white",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Regione
                          </th>
                          <th
                            style={{
                              color: "white",
                              border: "1px solid white",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Size
                          </th>
                          <th
                            style={{
                              color: "white",
                              border: "1px solid white",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Ateco
                          </th>
                          <th
                            style={{
                              color: "white",
                              border: "1px solid white",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            Deminimis
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pivas.map((piva) => (
                          <tr
                            key={piva.id}
                            onClick={() => handleRowSelect2(piva)}
                            style={{
                              border: `2px solid ${
                                selectedPivaId === piva.id
                                  ? "#e0e0e0"
                                  : "#e0e0e0"
                              }`,
                              backgroundColor:
                                selectedPivaId === piva.id
                                  ? "#FF5758"
                                  : "black",
                              cursor: "pointer",
                            }}
                          >
                            <td
                              style={{
                                color: "white",
                                border: "1px solid white",
                                padding: "8px",
                              }}
                            >
                              {piva.piva}
                            </td>
                            <td
                              style={{
                                color: "white",
                                border: "1px solid white",
                                padding: "8px",
                              }}
                            >
                              {piva.companyName}
                            </td>
                            <td
                              style={{
                                color: "white",
                                border: "1px solid white",
                                padding: "8px",
                              }}
                            >
                              {piva.regione}
                            </td>
                            <td
                              style={{
                                color: "white",
                                border: "1px solid white",
                                padding: "8px",
                              }}
                            >
                              {piva.companySize}
                            </td>
                            <td
                              style={{
                                color: "white",
                                border: "1px solid white",
                                padding: "8px",
                              }}
                            >
                              {typeof piva.companyAteco === "string"
                                ? extractBase(piva.companyAteco)
                                : "N/A"}
                            </td>
                            <td
                              style={{
                                color: "white",
                                border: "1px solid white",
                                padding: "8px",
                              }}
                            >
                              {piva.deminimis}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>Per iniziare inserisci una piva</div>
                  )}
                </div>
                <h3 style={{ marginBottom: "0px", marginTop: "20px" }}></h3>
                {selectedPivaData && (
                  <div
                    style={{
                      fontSize: "18px",
                      padding: "5px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      background: "black",
                      color: "white",
                    }}
                  >
                    {/* {selectedPivaData.companyName} - {selectedPivaData.piva} */}

                    <h2>
                      Obiettivi Attuali
                      {!isFilterEnabled && (
                        <button
                          className="filter-services-box"
                          onClick={() => {
                            window.scrollTo(0, 0);
                            navigate("/service-offers-pros");
                          }}
                        >
                          UPGRADE
                        </button>
                      )}
                      <div className="agent2comm-goals1">
                        <div className="agent2comm-goals3">
                          {isFilterEnabled ? (
                            currentGoals.length > 0 ? (
                              <ul
                                className="agent2comm-goals4"
                                style={{ marginBottom: "2px" }}
                              >
                                {currentGoals.map((goal, index) => (
                                  <li key={index} className="agent2comm-goals5">
                                    <span className="agent2comm-goals7">
                                      &#10003;
                                    </span>
                                    <span className="agent2comm-goals6">
                                      {goal}
                                    </span>
                                    <button
                                      onClick={() => handleRemoveGoal(goal)}
                                      className="agent2comm-goals8"
                                      aria-label={`Remove goal: ${goal}`}
                                    >
                                      &#10006;
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p className="agent2comm-goals9">
                                Nessun obiettivo ancora aggiunto
                              </p>
                            )
                          ) : (
                            <p className="agent2comm-goals9">
                              Disponibile solo con un UPGRADE
                            </p>
                          )}
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <button
                          type="button"
                          onClick={() => {
                            setIsGoalInputVisible(true); // Show the input field

                            // Trigger backend process for piva and docId
                            sendPivaToBackendforConfirm(
                              selectedPivaData.piva,
                              selectedPivaData.id
                            );
                          }}
                          className="logout-button"
                          style={{ marginRight: "10px", fontSize: "16px" }}
                          disabled={!isFilterEnabled}
                        >
                          + Obiettivi
                        </button>

                        <button
                          type="button"
                          ref={buttonRef}
                          onClick={async () => {
                            setIsProcessing(true); // Show spinner
                            await processGoals(
                              selectedPivaData.piva,
                              selectedPivaData.id
                            );
                            setIsProcessing(false); // Hide spinner
                          }}
                          className="logout-button"
                          style={{
                            marginRight: "10px",
                            fontSize: "16px",
                            position: "relative",
                            paddingRight: isProcessing ? "40px" : "10px",
                            cursor: isProcessing ? "not-allowed" : "pointer",
                            opacity: isProcessing ? 0.9 : 1,
                            background: isProcessing ? "grey" : "#092f7a",
                            border: "1px solid white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          disabled={isProcessing || !isFilterEnabled}
                        >
                          {isProcessing ? (
                            <>
                              <div className="custom-spinner"></div>
                              <span style={{ marginLeft: "1px" }}>
                                Caricamento
                              </span>
                            </>
                          ) : (
                            "Confermare"
                          )}
                        </button>
                      </div>
                      {isGoalInputVisible && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="text"
                            value={goalInput}
                            onChange={(e) => setGoalInput(e.target.value)}
                            onKeyDown={(e) =>
                              handleGoalSubmit(e, selectedPivaData?.pivaId)
                            }
                            placeholder="Inserisci il tuo obiettivo"
                            className="goalplus-box"
                          />
                          <button
                            onClick={(e) =>
                              handleGoalSubmit(e, selectedPivaData?.pivaId)
                            }
                            style={{ marginLeft: "1px" }}
                            className="logout-button"
                          >
                            +
                          </button>
                        </div>
                      )}
                    </h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {showFiltersGroups && (
          <div className="incentivy-container-comm">
            <div className="agent2-containers-comm">
              <div ref={nextSectionRef} className="incentivy-container-commer">
                <h2 className="custom-headingHJ">
                  {/* {firstName
                    ? capitalizeFirstLetter(firstName)
                    : "No user data available or user not signed in."}
                  ,  */} Filtrando per Regione{" "}
                  <span style={{ color: "#FF5758" }}>({regione})</span>, Ateco{" "}
                  <span style={{ color: "#FF5758" }}>
                    ({extractBase(atecoL3)}) 
                  </span>{" "}
                  e Dimensione{" "} <span style={{ color: "#FF5758" }}>({companySize})</span>
{/*                   <span style={{ color: "#FF5758" }}>({companySize})</span>: ci
                  sono <span style={{ color: "#FF5758" }}>{filteredCount}</span>{" "}
                  potenziali incentivi per{" "} */}
                 {/*  {companyName
                    ? companyName
                    : "No user data available or user not signed in."} */}
                  <span
                    style={{
                      color: "white",
                      fontSize: "20px",
                      lineHeight: "1.5rem",
                      fontWeight: "normal", // Ensures the text is not bold
                      display: "block",
                      maxWidth: "450px", // Sets maximum width for the text
                      margin: "0 auto", // Centers the block if needed
                    }}
                  ></span>
                </h2>

                {/*   <button
                  className="button-searchi"
                  onClick={() => setShowFiltersGroups((prev) => !prev)}
                >
                  {showFiltersGroups ? "Nascondi Filtri" : "Mostra Filtri"}
                </button>

                <button className="button-searchi">Reimposta Filtri</button> */}

                <div
                  className="filters-searchxy"
                  style={{ paddingTop: "20px" }}
                >
                  {!isFilterEnabled && (
                    <button
                      className="filter-services-box"
                      onClick={() => navigate("/service-offers-pros")}
                    >
                      UPGRADE
                    </button>
                  )}
                  <div className="filters-searchx">
                    <div className="restricted-filters-services">
                      Personalizzato
                      <div className="toggle-switch-container-paid">
                        <ToggleSwitchSearch
                          label="HyperFilter"
                          onChange={(value) =>
                            handleFilterChange("hyperFilter", value)
                          }
                          disabled={!isFilterEnabled}
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            Basato su AI. Include comune
                          </span>
                        </span>
                      </div>
                      <div className="toggle-switch-container-paid">
                        <ToggleSwitchSearch
                          label="Obiettivi"
                          onChange={(value) =>
                            handleFilterChange("strategy", value)
                          }
                          disabled={!isFilterEnabled}
                        />
                        <span className="info-icon">
                          i
                          <span className="tooltip-texty">
                            In base ai tuoi obiettivi
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="search-container" ref={searchContainerRef}>
                    <input
                      type="text"
                      placeholder="Ricerca per titolo" 
                      value={filters.searchKeyword}
                      onChange={(e) =>
                        handleFilterChange("searchKeyword", e.target.value)
                      }
                      onClick={() => setIsDropdownVisible(true)} // Show the dropdown when the input is clicked
                    />
                    {isDropdownVisible &&
                      filters.searchKeyword &&
                      incentives.length > 0 && (
                        <ul className="search-results">
                          {incentives.slice(0, 5).map((incentive) => (
                            <li
                              className="search-result-item"
                              key={incentive.ID_Incentivo}
                              onClick={() => handleItemClick(incentive.Titolo)}
                            >
                              {incentive.Titolo}
                            </li>
                          ))}
                        </ul>
                      )}
                  </div>

                  <div className="filters-wrapper-profs">
                    <div className="dropdown-container-profsLV">
                      <select
                        id="level-dropdown"
                        className="level-dropdownLV"
                        value={filters.level} // Bind to the filters.level value
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          handleFilterChange("level", selectedValue); // Update filters.level
                        }}
                      >
                        <option value="">Livello</option>
                        {levels.map((level) => (
                          <option key={level} value={level}>
                            {level}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="toggle-switch-container-profs">
                      <ToggleSwitchSearch
                        label="Preferiti"
                        onChange={(value) => handleFilterChange("thumb", value)}
                      />
                      <span className="info-icon">
                        i<span className="tooltip-texty">I tuoi preferiti</span>
                      </span>
                    </div>

                    <div className="toggle-switch-container-profs">
                      <ToggleSwitchSearch
                        label="Settimana Corrente"
                        onChange={(value) => handleFilterChange("CW", value)}
                      />
                      <span className="info-icon">
                        i
                        <span className="tooltip-texty">
                          Novità questa settimana
                        </span>
                      </span>
                    </div>

                    <div className="toggle-switch-container-profs">
                      <ToggleSwitchSearch
                        label="Agevolazione"
                        onChange={(value) =>
                          handleFilterChange("Agevolazione", value)
                        }
                      />
                      <span className="info-icon">
                        i<span className="tooltip-texty">Sgravi fiscali</span>
                      </span>
                    </div>

                    <div className="toggle-switch-container-profs">
                      <ToggleSwitchSearch
                        label="Fondo Perduto"
                        onChange={(value) =>
                          handleFilterChange("Fondo_perduto", value)
                        }
                      />
                      <span className="info-icon">
                        i
                        <span className="tooltip-texty">
                          Non richiedono il rimborso
                        </span>
                      </span>
                    </div>

                    <div className="toggle-switch-container-profs">
                      <ToggleSwitchSearch
                        label="Capitale di rischio"
                        onChange={(value) =>
                          handleFilterChange("Capitale_di_rischio", value)
                        }
                      />
                      <span className="info-icon">
                        i
                        <span className="tooltip-texty">
                          Investimento in equity
                        </span>
                      </span>
                    </div>

                    <div className="toggle-switch-container-profs">
                      <ToggleSwitchSearch
                        label="Prestito"
                        onChange={(value) =>
                          handleFilterChange("Prestito", value)
                        }
                      />
                      <span className="info-icon">
                        i
                        <span className="tooltip-texty">
                          Devono essere restituiti
                        </span>
                      </span>
                    </div>

                    <div className="toggle-switch-container-profs">
                      <ToggleSwitchSearch
                        label="Interventi Garanzia"
                        onChange={(value) =>
                          handleFilterChange("Interventi_garanzia", value)
                        }
                      />
                      <span className="info-icon">
                        i
                        <span className="tooltip-texty">
                          Facilitare l'accesso al credito
                        </span>
                      </span>
                    </div>

                    <div className="toggle-switch-container-profs">
                      <ToggleSwitchSearch
                        label="Anticipo rimborsabile"
                        onChange={(value) =>
                          handleFilterChange("Anticipo_rimborsabile", value)
                        }
                      />
                      <span className="info-icon">
                        i
                        <span className="tooltip-texty">
                          Ricevere un'importo di denaro in anticipo, che dovrà
                          essere successivamente rimborsato
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "20px",
                    color: "white",
                    marginTop: "25px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            fontSize: "16px",
          }}
        >
          <div>
            <div style={{ textAlign: "left", fontSize: "16px" }}>
              <div>
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                {/* {responseMessage && formatResponseMessage(responseMessage)} */}
              </div>
            </div>
            <div className="incentivy-container-comm">
              <div className="incentivy-container-comm3">
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                <h2
                  style={{
                    marginTop: "20px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                 <span style={{ color: "#FF5758" }}>{filteredCount}</span>{" "} Incentivi Dedicati
                </h2>
                <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          margin: "20px 0",
        }}
      >
        <button
          onClick={() => handleSortToggle("openingDate")}
          style={{
            border: "1px solid white",
            backgroundColor: "transparent",
            color: "white",
            padding: "10px 15px",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          Data Apertura {isAscending.openingDate ? "▲" : "▼"}
        </button>
        <button
          onClick={() => handleSortToggle("closingDate")}
          style={{
            border: "1px solid white",
            backgroundColor: "transparent",
            color: "white",
            padding: "10px 15px",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          Data Chiusura {isAscending.closingDate ? "▲" : "▼"}
        </button>
      </div>
                {loading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ display: "block", margin: "auto", color: "white" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : incentives.length > 0 ? (
                  <ul style={{ padding: "10px", listStyleType: "none" }}>
                   {visibleIncentives
                    .filter((doc) => doc.filtered === 1) // Apply filtering
                    .map((doc, index) => (
                        <li
                          key={index}
                          style={{
                            marginBottom: "20px",
                            border: "1px solid #ccc",
                            padding: "8px",
                            borderRadius: "8px",
                            boxShadow:
                              "0 4px 6px rgba(0,0,0,0.1), 5px 5px 5px #FF5758",
                            backgroundColor: "#fff", // adds a clean white background
                            lineHeight: "2rem",
                            fontSize: "20px",
                            position: "relative", // Needed for absolute positioning of the arrow
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "2px",
                              right: "10px",
                              display: "flex",
                              alignItems: "center",
                              margin: "10px 0 10px 0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <button
                                onClick={() =>
                                  updateThumbs(doc.ID_Incentivo, "Y")
                                }
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  backgroundColor: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  fontSize: "25px",
                                }}
                              >
                                <img
                                  src={thumbup} // Path to your SVG file
                                  alt="Thumbs Up"
                                  style={{ width: "30px", height: "30px" }} // Adjust width/height
                                />
                              </button>
                              <button
                                onClick={() =>
                                  updateThumbs(doc.ID_Incentivo, "N")
                                }
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  fontSize: "25px",
                                }}
                              >
                                <img
                                  src={thumbdown} // Path to your SVG file
                                  alt="Thumbs Down"
                                  style={{ width: "30px", height: "30px" }} // Adjust width/height
                                />
                              </button>
                            </div>
                            <button
                              className="button-stylish"
                              onClick={() => {
                                //console.log("Document data:", doc); // Log the entire document
                                const assistant = doc.AssistantID; // Rename AssistantID to assistant
                                startNewConversation(
                                  atecoL3,
                                  regione,
                                  assistant,
                                  doc.ID_Incentivo,
                                  firstName,
                                  companyName,
                                  companySize,
                                  companyEmployees,
                                  companyTurnover
                                );
                              }}
                              style={{ cursor: "pointer", fontSize: "35px" }}
                            >
                              <img
                                src={rightarrow} // Path to your SVG file
                                alt="navigate to criteria page"
                                style={{ width: "30px", height: "30px" }} // Adjust width/height
                              />
                            </button>
                          </div>
                        <div
                          className="topyadj"
                          style={{
                            backgroundColor: getLevelColor(doc.Level),
                            color: "#fff", // Optional: Adjust text color for readability
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          {formatDate(doc.Level)}
                        </div>
                        <img
                          className="image-article-searchid"
                          src={doc.urlImage}
                          alt={`Image of ${doc.Titolo}`}
                        /> 
                          <h2
                            className="title-searched"
                            style={{ marginTop: "20px", marginBottom: "20px" }}
                          >
                            {doc.Titolo}
                          </h2>
                          <strong style={{marginBottom:"20px"}}>
                            {doc.KeyBenefit} <br />
                          </strong>
                         {/*  <div style={{ marginTop: "10px" }}>
                            <strong>Apertura:</strong>{" "}
                            {formatDate(doc.Data_apertura)}{" "}
                            <strong>Chiusura:</strong>{" "}
                            {formatDate(doc.Data_chiusura)}
                          </div> */}
                          <div>
                            {doc.Obiettivo_Finalita.map((goal) => (
                              <span
                                key={goal}
                                className={`category-box ${getCategoryClass(
                                  goal
                                )}`}
                                style={{ marginTop: "10px" }}
                              >
                                {goal}
                              </span>
                            ))}
                          </div>
                          <p style={{marginTop: "10px"}}>
                              <a
                                href={doc.Link_istituzionale}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                info
                              </a>
                            </p>
                          <p style={{ color: "black", fontSize:"13px", marginBottom:"1px" }}>
                              Chiusura:{" "}
                              {calculateDaysLeft(doc.Data_chiusura)}
                            </p>
                          <Timeline 
                            openingDate={doc.Data_apertura} 
                            closingDate={doc.Data_chiusura} 
                          />
                        </li>
                      ))}
                  </ul>
                ) : (
                  <p style={{ color: "white" }}>(Aggiungi PIVA)</p>
                )}
                {visibleCount < sortedIncentives.length && (
                    <button
                      style={{
                        display: "block",
                        margin: "20px auto",
                        padding: "10px 15px",
                        backgroundColor: "#092f7a",
                        color: "#fff",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "16px",
                        border: "none",
                      }}
                      onClick={handleReadMore}
                    >
                      Read More{" "}
                      <span style={{ fontSize: "20px", marginLeft: "5px" }}>
                        ↓
                      </span>
                    </button>
                  )}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncentiveSearchCommercialista;
